// utils/imageUpload.js
import { storage } from "../../firebase/firebase-config";
import imageCompression from "browser-image-compression";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

const uploadImage = async (file, folder) => {
  try {
    // Compress the image
    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    const compressedFile = await imageCompression(file, options);

    // Create a reference to the storage location
    const storageRef = ref(storage, `images/${folder}/${compressedFile.name}`);

    // Upload the compressed image
    await uploadBytes(storageRef, compressedFile);

    // Get the download URL
    const imageURL = await getDownloadURL(storageRef);

    return imageURL;
  } catch (error) {
    console.error("Error uploading image:", error);
    throw error;
  }
};

export default uploadImage;
