import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import "firebase/compat/analytics";
import "firebase/compat/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
const firebaseConfig = {
  apiKey: "AIzaSyAQZx-4oFf8GZLIxlak8rlnT2mqaD2ZKbE",
  authDomain: "revo-47fdb.firebaseapp.com",
  projectId: "revo-47fdb",
  storageBucket: "revo-47fdb.appspot.com",
  messagingSenderId: "1018634798138",
  appId: "1:1018634798138:web:252ff360f3da479d9a2103",
  measurementId: "G-S5M9PZTTDG"
};

firebase.initializeApp(firebaseConfig);


const storage = firebase.storage();

const db = firebase.firestore();
const auth = firebase.auth();
const analytics = firebase.analytics();

export { storage, db, auth, analytics };
