import { auth, db } from "../../../firebase/firebase-config";
import { ADMIN_API_URL } from "../../variables/globals";
import { types } from "../types/types";

export const refreshToken = async () => {
  const token = await auth.currentUser.getIdToken(true);

  return token;
};

export const login = (email, password, setloading) => {
  return async (dispatch) => {
    try {
      const response = await auth.signInWithEmailAndPassword(email, password);
      const idToken = await response.user.getIdToken(true);
      const metadata = await response.user.getIdTokenResult();
      // console.log(metadata);

      if (
        metadata.claims.superadmin ||
        metadata.claims.admin ||
        metadata.claims.almighty
      ) {
        dispatch(
          signInWithEmail(response.user.toJSON(), metadata.claims, idToken)
        );
        dispatch(setAuthError(""));
        setloading(false);
      } else {
        dispatch(setAuthError("Usuario no es administrador"));
        console.error("User does not have admin privileges.");
        setloading(false);
      }
    } catch (error) {
      dispatch(setAuthError(error.code));
      setloading(false);
      console.error(error);
    }
  };
};

export const logoutUser = () => {
  return async (dispatch) => {
    try {
      await auth.signOut();
      dispatch(logout());
    } catch (error) {
      console.error(error);
    }
  };
};

export const signInWithEmail = (user, claims, idToken) => ({
  type: types.login,
  payload: {
    user,
    loggedIn: true,
    isAlmighty: claims.almighty,
    isSuperAdmin: claims.superadmin,
    isAdmin: claims.admin,
    isModerator: claims.moderator,
    idToken: idToken,
  },
});

export const setAuthError = (errorCode) => {
  let errorMsg = "";

  switch (errorCode) {
    case "auth/user-not-found":
      errorMsg = "Usuario no encontrado. Verifique sus credenciales.";
      break;
    case "auth/wrong-password":
      errorMsg = "Contraseña incorrecta. Verifique sus credenciales.";
      break;
    // Add more error codes and corresponding messages as needed
    default:
      errorMsg = "Error en el inicio de sesión. Verifique sus credenciales.";
  }

  return {
    type: types.errorMsg,
    payload: {
      errorCode: errorCode,
      errorMsg: errorMsg,
    },
  };
};

export const setIsLoadingAuth = (isLoading) => ({
  type: types.isLoadingAuth,
  payload: {
    isLoadingAuth: isLoading,
  },
});

export const logout = () => ({
  type: types.logout,
  payload: {},
});

export const ReCaptcha = (token) => {
  // https://www.google.com/recaptcha/api/siteverify
};
