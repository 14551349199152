import React from "react";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { MdDelete } from "react-icons/md";
import { styles } from './../../../assets/styles/styles';

const Preview = ({files, removeAll}) => {
  
  const filepath = files.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes{" "}
      {/* <button onClick={removeFile(file)}>Remove File</button> */}
      <IconButton color="error" onClick={removeAll} sx={{boxShadow:'none'}}>
        <MdDelete size={18} />
      </IconButton>
    </li>
  ));
  const thumbs = files.map((file) => {
    console.log(file.preview); // Add this console log statement
    return (
      <div style={styles.thumb} key={file.name}>
        <div style={styles.thumbInner}>
          <img src={file.preview} style={styles.img} />
        </div>
      </div>
    );
  });

  return (
    <div className="center flex-column">
    <aside style={styles.modalThumbsContainer}>
      <ul className="" style={{padding:0}}>
        {thumbs} {filepath}
      </ul>
    </aside>
  </div>
  );
};

export default Preview;
