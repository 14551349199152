import React, { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { login } from "../../store/actions/auth";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { styles } from "../../../assets/styles/styles";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const loginCardStyle = {
  backgroundColor: `${styles.white}`,
  boxShadow: 0,
  borderRadius: 1,
  border: "none",
  marginTop: 8,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  maxWidth: 400,
};

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { errorMsg } = useSelector((state) => state.auth);
  const [loading, setloading] = useState(false);
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: yup.object({
      email: yup
        .string("Ingresa un correo electrónico")
        .email("Ingresa un correo electrónico válido")
        .required("Email is required"),
      password: yup
        .string("Ingresa una contraseña")
        .min(6, "Mínimo 6 caractéres")
        .required("La contraseña es requerida"),
    }),
    onSubmit: async (values) => {
      try {
        setloading(true);
        dispatch(login(values.email, values.password, setloading));
        // Clear any previous error message on successful login
      } catch (error) {
        // Handle the unsuccessful login here
        console.error("Login failed:", error);
        // Set the error message here
      }
    },
  });

  const handleLogin = (values) => {
    dispatch(login(values.email, values.password));
  };

  return (
    <div id="login">
      <div className="login-wrapper ">
        <div className="align">
          <CssBaseline />
          <Box
            sx={[styles.cardStyle, { justifyContent: "center", width: 375 }]}
            className="theme-card"
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <div className="center">
                <img
                  style={{ width: "24px", height: "24px" }}
                  src={require("../../../assets/img/logos/logoWhite.png")}
                  alt="onecore"
                />
              </div>
            </Avatar>
            <Typography
              component="h1"
              variant="h5"
              sx={{ color: "#dda74f", margin: "0 auto" }}
            >
              Iniciar Sesión
            </Typography>
            <Box component="form" onSubmit={formik.handleSubmit} sx={{ p: 2 }}>
              <TextField
                margin="normal"
                fullWidth
                id="email"
                label="Correo Electronico"
                name="email"
                type="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                variant="standard"
              />

              <TextField
                margin="normal"
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
                variant="standard"
              />

              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Recordarme"
              />
              <Button
                type="submit"
                fullWidth
                color="primary"
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={formik.handleSubmit}
              >
                Iniciar Sesión{" "}
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    Olvido su contraseña?
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
          {errorMsg && ( // Display error message if it exists
            <div className="text-danger mt-4" style={{ color: "red" }}>
              {errorMsg}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Login;
