import { SET_IS_LOADING, SET_SCHEDULE } from "../actions/schedules";
import { types } from "../types/types";

const initialState = {
 schedules: [],
  isLoading: false,
};


export const schedulesReducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_SCHEDULE:
        return {
          ...state,
          schedules: action.payload.schedules,
          // displayName: action.payload.displayName,
        };
  
      case SET_IS_LOADING:
        return {
          ...state,
          isLoading: action.payload.isLoading,
          // displayName: action.payload.displayName,
        };
  
      default:
        return state;
    }
  };
  