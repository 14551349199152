import { types } from "../types/types";
import { SET_ITEMS, SET_COURSES, SET_IS_LOADING } from '../actions/items';

const initialState = {
  items: null,
  category: "all",
  isLoading: false,
  courses:null,
  searchValue: "",
};

export const itemsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ITEMS:
      return {
        ...state,
        items: action.payload.items,
        // displayName: action.payload.displayName,
      };
      case SET_COURSES:
        return {
          ...state,
          courses: action.payload.courses,
          // displayName: action.payload.displayName,
        };
    case types.changeCategory:
      return {
        ...state,
        category: action.payload.category,
        // displayName: action.payload.displayName,
      };
      case SET_IS_LOADING:
        return {
          ...state,
          isLoading: action.payload.isLoading,
          // displayName: action.payload.displayName,
        };
    case types.searchValue:
      return {
        ...state,
        searchValue: action.payload.searchValue,
        // displayName: action.payload.displayName,
      };
    default:
      return state;
  }
};
