import { auth, db } from "../../../firebase/firebase-config";
import { ADMIN_API_URL } from "../../variables/globals";
import { types } from "../types/types";

export const getAllUsers = () => {
  return (dispatch) => {
    const users = [];
    dispatch(isLoading(true));
    db.collection("users")
      .orderBy("cdate", "desc")
      .limit(15)
      .get()
      .then(async (itemsFromFs) => {
        itemsFromFs.docs.forEach((item) =>
          users.push({ id: item.id, ...item.data() })
        );

        dispatch(getUsers(users));
        dispatch(isLoading(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(isLoading(false));
      });
  };
};
export const getDbRef = async () => {
  try {
    const doc = await db.collection("dbRefs").doc("users").get();
    if (doc.exists) {
      const data = doc.data();
      if (data.count && typeof data.count === "number") {
        return data.count; // Return the count field directly
      }
    }
    return 0; // Return 0 if the document doesn't exist or count field is not valid
  } catch (err) {
    console.log(err);
    return 0; // Return 0 in case of an error
  }
};

export const getDbRefReq = async (currentType) => {
  try {
    const doc = await db.collection("dbRefs").doc("requests").get();
    if (doc.exists) {
      const data = doc.data();
      if (data[currentType] && typeof data[currentType] === "number") {
        return data[currentType]; // Return the value of the currentType field
      }
    }
    return 0; // Return 0 if the document doesn't exist or the currentType field is not valid
  } catch (err) {
    console.log(err);
    return 0; // Return 0 in case of an error
  }
};

export const startListeningToUsers = (limit) => {
  return (dispatch) => {
    dispatch(isLoading(true));
    const unsubscribe = db
      .collection("users")
      .orderBy("cdate", "desc")
      .limit(limit)
      .onSnapshot(
        (snapshot) => {
          const users = [];
          snapshot.docs.forEach((item) =>
            users.push({ id: item.id, ...item.data() })
          );

          dispatch(getUsers(users));
          dispatch(isLoading(false));
        },
        (error) => {
          console.error("Error listening to users:", error);
          dispatch(isLoading(false));
        }
      );

    // You can return the unsubscribe function if needed
    return unsubscribe;
  };
};

export const startListeningToRequests = () => {
  return (dispatch) => {
    dispatch(isLoading(true));

    const unsubscribe = db
      .collection("contacts")
      .doc("register")
      .collection("requests")
      .where("status", "==", "pending")
      .orderBy("cdate", "desc")

      .onSnapshot(
        (snapshot) => {
          const requests = [];
          snapshot.docs.forEach((item) =>
            requests.push({ id: item.id, ...item.data() })
          );

          dispatch(getRequests(requests));
          dispatch(isLoading(false));
        },
        (error) => {
          console.error("Error listening to users:", error);
          dispatch(isLoading(false));
        }
      );

    // You can return the unsubscribe function if needed
    return unsubscribe;
  };
};

export const startListeningToSpecificRequests = (limit, type, tabValue) => {
  return (dispatch) => {
    dispatch(isLoading(true));

    let query = db
      .collection("contacts")
      .doc("register")
      .collection("requests");

    // Apply filters based on tabValue and type
    if (tabValue === 0) {
      query = query
        .where("status", "==", type)
        .where("selectedAnswer", "==", 1);
    } else if (tabValue === 1) {
      query = query
        .where("status", "==", type)
        .where("selectedAnswer", "==", 2);
    } else {
      query = query.where("status", "==", type);
    }

    query = query.orderBy("cdate", "desc").limit(limit);

    const unsubscribe = query.onSnapshot(
      (snapshot) => {
        const requests = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        dispatch(getRequests(requests));
        dispatch(isLoading(false));
      },
      (error) => {
        console.error("Error listening to requests:", error);
        dispatch(isLoading(false));
      }
    );

    return unsubscribe;
  };
};
export const searchUsers = async (searchType, searchValue) => {
  try {
    let querySnapshot;

    if (searchType === "email") {
      querySnapshot = await db
        .collection("users")
        .where("email", ">=", searchValue)
        .where("email", "<=", searchValue + "\uf8ff")
        .get();
    } else if (searchType === "brokerId") {
      // Filtra por brokerId que contengan la palabra clave
      querySnapshot = await db
        .collection("users")
        .where("brokerId", ">=", searchValue)
        .where("brokerId", "<=", searchValue + "\uf8ff")
        .get();
    } else if (searchType === "name") {
      // Filtra por brokerId que contengan la palabra clave
      querySnapshot = await db
        .collection("users")
        .where("name", ">=", searchValue)
        .where("name", "<=", searchValue + "\uf8ff")
        .get();
    } else if (searchType === "discordId") {
      // Filtra por discordId que contengan la palabra clave
      querySnapshot = await db
        .collection("users")
        .where("discordId", ">=", searchValue)
        .where("discordId", "<=", searchValue + "\uf8ff")
        .get();
    }

    // Mapea los resultados para obtener los datos de los usuarios
    const users = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    return users;
  } catch (error) {
    console.error("Error searching users:", error);
    throw error;
  }
};
export const searchRequests = async (searchType, searchValue, type) => {
  try {
    let querySnapshot;
    if (searchType === "email") {
      querySnapshot = await db
        .collection("contacts")
        .doc("register")
        .collection("requests")
        .where("email", ">=", searchValue)
        .where("email", "<=", searchValue + "\uf8ff")
        .where("status", "==", type) // Filter by status
        .get();
    } else if (searchType === "name") {
      // Filter by name, last name, or second last name containing the keyword
      querySnapshot = await db
        .collection("contacts")
        .doc("register")
        .collection("requests")
        .where("name", ">=", searchValue)
        .where("name", "<=", searchValue + "\uf8ff")
        .where("status", "==", type) // Filter by status
        .get();
    } else if (searchType === "brokerId") {
      // Filter by brokerId containing the keyword
      querySnapshot = await db
        .collection("contacts")
        .doc("register")
        .collection("requests")
        .where("brokerId", ">=", searchValue)
        .where("brokerId", "<=", searchValue + "\uf8ff")
        .where("status", "==", type) // Filter by status
        .get();
    } else if (searchType === "discordId") {
      // Filter by discordId containing the keyword
      querySnapshot = await db
        .collection("contacts")
        .doc("register")
        .collection("requests")
        .where("discordId", ">=", searchValue)
        .where("discordId", "<=", searchValue + "\uf8ff")
        .where("status", "==", type) // Filter by status
        .get();
    }

    // Map the results to obtain the request data
    const requests = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    return requests;
  } catch (error) {
    console.error("Error searching requests:", error);
    throw error;
  }
};
export const searchNonPendingRequests = async (searchType, searchValue) => {
  try {
    let querySnapshot;
    if (searchType === "email") {
      querySnapshot = await db
        .collection("contacts")
        .doc("register")
        .collection("requests")
        .where("email", ">=", searchValue)
        .where("email", "<=", searchValue + "\uf8ff")
        .where("status", "in", ["accepted", "rejected"])
        .get();
    } else if (searchType === "name") {
      // Filter by name, last name, or second last name containing the keyword
      querySnapshot = await db
        .collection("contacts")
        .doc("register")
        .collection("requests")
        .where("name", ">=", searchValue)
        .where("name", "<=", searchValue + "\uf8ff")
        .where("status", "in", ["accepted", "rejected"])
        .get();
    } else if (searchType === "brokerId") {
      // Filter by brokerId containing the keyword
      querySnapshot = await db
        .collection("contacts")
        .doc("register")
        .collection("requests")
        .where("brokerId", ">=", searchValue)
        .where("brokerId", "<=", searchValue + "\uf8ff")
        .where("status", "in", ["accepted", "rejected"])
        .get();
    } else if (searchType === "discordId") {
      // Filter by discordId containing the keyword
      querySnapshot = await db
        .collection("contacts")
        .doc("register")
        .collection("requests")
        .where("discordId", ">=", searchValue)
        .where("discordId", "<=", searchValue + "\uf8ff")
        .where("status", "in", ["accepted", "rejected"])
        .get();
    }

    // Map the results to obtain the request data
    const requests = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    return requests;
  } catch (error) {
    console.error("Error searching requests:", error);
    throw error;
  }
};
export const getAllRequests = () => {
  return (dispatch) => {
    const requests = [];
    dispatch(isLoading(true));
    db.collection("contacts")
      .doc("register")
      .collection("requests")
      .orderBy("cdate", "desc")
      .get()
      .then(async (itemsFromFs) => {
        itemsFromFs.docs.forEach((item) =>
          requests.push({ id: item.id, ...item.data() })
        );
        dispatch(getRequests(requests));
        dispatch(isLoading(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(isLoading(false));
      });
  };
};
export const getPendingRequests = () => {
  return (dispatch) => {
    const requests = [];
    dispatch(isLoading(true));
    db.collection("contacts")
      .doc("register")
      .collection("requests")
      .orderBy("cdate", "desc")
      .where("status", "==", "pending")
      .get()
      .then(async (itemsFromFs) => {
        itemsFromFs.docs.forEach((item) =>
          requests.push({ id: item.id, ...item.data() })
        );
        dispatch(getRequests(requests));
        dispatch(isLoading(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(isLoading(false));
      });
  };
};
export const checkIfUserExists = async (brokerId) => {
  const querySnapshot = await db
    .collection("users")
    .where("brokerId", "==", brokerId)
    .where("isDisabled", "==", false)
    .get();

  if (!querySnapshot.empty) {
    const userData = querySnapshot.docs[0].data();
    return { exists: true, isDisabled: userData.isDisabled };
  } else {
    return { exists: false, isDisabled: false };
  }
};

export const createUser = async (data, token) => {
  const url = ADMIN_API_URL + "/createUser";

  const body = {
    email: data.email,

    displayName:
      data.name +
      " " +
      data.lastname +
      " " +
      (data.lastname2 ? data.lastname2 : " "),
    name: data.name,
    lastname: data.lastname,
    lastname2: data.lastname2,
    phone: data.phone,
    discordId: data.discordId,
    brokerId: data.brokerId,
    tier: data.tier,
  };

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(body),
  };
  // console.log("body : ", JSON.stringify(body));
  try {
    const response = await fetch(url, options);
    const res = await response.json();
    // console.log("res", res, "response", response);
    return res;
  } catch (error) {
    throw new Error(error);
  }
};

export const rejectUserEp = async (email, token) => {
  const url = ADMIN_API_URL + "/rejectUser";

  const body = {
    email: email,
  };

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(body),
  };
  // console.log("body : ", JSON.stringify(body));
  try {
    const response = await fetch(url, options);
    const res = await response.json();
    // console.log("res", res, "response", response);
    return res;
  } catch (error) {
    throw new Error(error);
  }
};
export const createAdmin = async (data, token) => {
  const url = ADMIN_API_URL + "/createAdmin";

  const body = {
    email: data.email,
    displayName:
      data.name +
      " " +
      data.lastname +
      " " +
      (data.lastname2 ? data.lastname2 : " "),
    name: data.name,
    password: data.password,
    lastname: data.lastname,
    lastname2: data.lastname2,
    phone: data.phone,
    discordId: data.discordId,
    brokerId: data.brokerId,
    tier: data.tier,
    adminClaim: data.adminClaim,
  };

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(body),
  };

  try {
    const response = await fetch(url, options);
    const res = await response.json();
    console.log("res", res, "response", response);
    return res;
  } catch (error) {
    throw new Error(error);
  }
};

export const editUser = async (data) => {
  const body = {
    displayName: `${data.name} ${data.lastname}${
      data.lastname2 ? ` ${data.lastname2}` : ""
    }`,
    name: data.name,
    lastname: data.lastname,
    lastname2: data.lastname2,
    phone: data.phone,
    discordId: data.discordId,
    isActive: data.isActive,
    isGoldPlan: data.isGoldPlan,
    discordActive: data.discordActive,
    brokerId: data.brokerId,
  };

  try {
    // await user.updateEmail(body.email);

    await db.collection("users").doc(data.id).set(body, { merge: true });

    console.log("User document and authentication updated successfully!");
  } catch (error) {
    console.error("Error updating user document and authentication:", error);
  }
};

export const writeHistory = async (
  values,
  isActiveChanged,
  isGoldPlanChanged,
  item,
  user
) => {
  try {
    const historyData = {
      admin: user.email, // Replace with actual admin information
      cdate: new Date(),
      tier:
        values.tier === 1
          ? "Basico"
          : values.tier === 2
          ? "Intermedio"
          : "Avanzado",
      isActiveChanged,
      isGoldPlanChanged,
    };

    await db
      .collection("users")
      .doc(item.id)
      .collection("history")
      .add(historyData);

    console.log("History record added successfully!");
  } catch (error) {
    console.error("Error adding history record:", error);
  }
};

export const upgradeUserByEmail = async (data) => {
  const body = {
    isActive: data.isActive,
    isGoldPlan: data.isGoldPlan,
  };

  try {
    // Fetch user document based on email
    const userQuerySnapshot = await db
      .collection("users")
      .where("email", "==", data.email)
      .get();
    console.log("aca");
    // Check if user exists
    if (!userQuerySnapshot.empty) {
      // Get the first document (assuming email is unique)
      const userDoc = userQuerySnapshot.docs[0];

      // Update user document with the provided body
      await userDoc.ref.set(body, { merge: true });

      console.log("User document updated successfully!");
    } else {
      console.error("No user found with the provided email:", data.email);
    }
  } catch (error) {
    console.error("Error updating user document and authentication:", error);
  }
};

export const deactivateUser = async (uid) => {
  try {
    // Update the user's document in Firestore to set isActive to false
    const userRef = db.collection("users").doc(uid);
    await userRef.set(
      {
        isDisabled: true,
        isActive: false,
        isGoldPlan: false,
      },
      { merge: true }
    );

    console.log(`User with UID ${uid} has been deactivated.`);
  } catch (error) {
    console.error("Error deactivating user:", error);
  }
};

export const deleteUser = async (data, token) => {
  const url = ADMIN_API_URL + "/deleteUser";

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(data),
  };

  try {
    const response = await fetch(url, options);
    const res = await response.json();
    // console.log("res", res, "response", response);
    return res;
  } catch (error) {
    throw new Error(error);
  }
};

export const activateUser = async (uid) => {
  try {
    const userRef = db.collection("users").doc(uid);
    console.log(uid, userRef);
    await userRef.set(
      {
        isDisabled: false,
        isActive: false,
        isGoldPlan: false,
      },
      { merge: true }
    );

    console.log(`User with UID ${uid} has been activated.`);
  } catch (error) {
    console.error("Error deactivating user:", error);
  }
};

export const deleteRequest = async (id) => {
  try {
    const userRef = db
      .collection("contacts")
      .doc("register")
      .collection("requests")
      .doc(id);
    console.log(id, userRef);
    await userRef.delete();

    console.log(`request deleted`);
  } catch (error) {
    console.error("Error deleting request:", error);
  }
};
export const updateRequest = async (id, data, adminName, reason) => {
  try {
    const userRef = db
      .collection("contacts")
      .doc("register")
      .collection("requests")
      .doc(id);

    const observationsCollectionRef = userRef.collection("observations");

    // Add observation based on the reason and data (accepted or rejected)
    await observationsCollectionRef.add({
      cdate: new Date(),
      text:
        reason === "Es mi primer ingreso a Revolution"
          ? data === "accepted"
            ? " Primer Ingreso : Aceptado"
            : " Primer Ingreso : Rechazado"
          : data === "accepted"
          ? " Solicitud Mejora: Aceptada"
          : " Solicitud Mejora: Rechazada",
      admin: adminName,
    });

    // Update the user's status field
    await userRef.set({ status: data }, { merge: true });

    // // Update counts in the dbRefs document based on the data (accepted or rejected)
    // const currentType = data === "accepted" ? "accepted" : "rejected";
    // const requestRef = db.collection("dbRefs").doc("requests");
    // const doc = await requestRef.get();

    // if (!doc.exists) {
    //   throw new Error("Document not found");
    // }

    // const currentCount = doc.data()[currentType] || 0;
    // const newCount = currentCount + 1;
    // const pendingFirstCount = doc.data()["pendingFirst"] || 0;
    // const pendingUpgradeCount = doc.data()["pendingUpgrade"] || 0;

    // let newPendingFCount = pendingFirstCount;
    // let newPendingUCount = pendingUpgradeCount;
    // if (reason === "Es mi primer ingreso a Revolution") {
    //   newPendingFCount = pendingFirstCount - 1;
    // } else {
    //   newPendingUCount = pendingUpgradeCount - 1;
    // }

    // const updateData = {};
    // updateData[currentType] = newCount;
    // updateData["pendingFirst"] = newPendingFCount;
    // updateData["pendingUpgrade"] = newPendingUCount;

    // await requestRef.update(updateData);

    console.log(`request updated`);
  } catch (error) {
    console.error("Error updating request:", error);
  }
};

export const getAdminInfo = async (id) => {
  try {
    const adminDoc = await db.collection("admins").doc(id).get();

    if (adminDoc.exists) {
      // Extract admin data from the document
      const adminData = adminDoc.data();
      return adminData;
    } else {
      // Admin document does not exist
      console.log("Admin document does not exist");
      return null;
    }
  } catch (error) {
    // Handle errors
    console.error("Error fetching admin data:", error);
    throw error;
  }
};

export const getChangesFromRequest = async (id) => {
  try {
    const snapshot = await db
      .collection("contacts")
      .doc("register")
      .collection("requests")
      .doc(id)
      .collection("observations")
      .orderBy("cdate", "desc") // Order by the 'cdate' field in descending order
      .get();

    const observations = snapshot.docs.map((doc) => doc.data());
    return observations;
  } catch (error) {
    console.error("Error getting observations:", error);
    return [];
  }
};

export const enableUser = async (data, token) => {
  const url = ADMIN_API_URL + "/deleteUser";

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(data),
  };

  try {
    const response = await fetch(url, options);
    const res = await response.json();
    // console.log("res", res, "response", response);
    return res;
  } catch (error) {
    throw new Error(error);
  }
};

// Nombre - Apellido- telefono- discord- id de cuenta de broker  tier es number
export const getUsers = (users) => ({
  type: types.getUsers,
  payload: {
    users,
  },
});
export const addMoreUsers = (newUsers) => ({
  type: types.addMoreUsers,
  payload: newUsers,
});
export const addMoreRequests = (newRequests) => ({
  type: types.addMoreRequests,
  payload: newRequests,
});
export const getRequests = (requests) => ({
  type: types.getRequests,
  payload: {
    requests,
  },
});
export const isLoading = (isLoading) => ({
  type: types.isLoadingUsers,
  payload: {
    isLoading,
  },
});
