import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { MdSearch, MdPhoto, MdRefresh, MdDelete } from "react-icons/md";
import Box from "@mui/material/Box";
import { styles } from "../../assets/styles/styles";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import {
  callDeleteAlert,
  callDeleteAlertItem,
  deleteItem,
  editLiveEventStatus,
} from "../store/actions/items";
import { useDispatch, useSelector } from "react-redux";
import MUIDataTable from "mui-datatables";
import { MdEdit } from "react-icons/md";
import CustomBodyWithSpinner from "../components/tables/CustomBodyWithSpinner";
import { getAllItems } from "../store/actions/items";
import AddItems from "../components/AddItems";
import ItemsEditModal from "../components/modals/ItemsEditModal.js";
import { Switch, Tab, Tabs, Typography, useTheme } from "@mui/material";
import LiveEventsEditModal from "../components/modals/LiveEventsEditModal";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

export default function ItemsView() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { isSuperAdmin, isAlmighty } = useSelector((state) => state.auth);
  const { items } = useSelector((state) => state.items);
  const [itemsFromDB, setItemsFromDB] = useState([]);
  const isFirstUse = useRef(true);
  const [refreshing, setRefreshing] = useState(false);
  const [loading, setLoading] = useState(true);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [liveeditModalOpen, setLiveEditModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedLiveEvent, setSelectedLiveEvent] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [homeItems, setHomeItems] = useState([]);
  const [liveItems, setLiveItems] = useState([]);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const getItemsFromFB = useCallback(async () => {
    try {
      setLoading(true);
      await dispatch(getAllItems());
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }, [dispatch]);

  useEffect(() => {
    if (isFirstUse.current) {
      setLoading(true);
      getItemsFromFB();
      isFirstUse.current = false;
    }
  }, [getItemsFromFB]);

  const handleChangeIsActive = (item, tag) => {
    // Check if isActive exists in item and toggle its value
    const newIsActive = item?.isActive ? item?.isActive : false;

    const confirmationMessage = newIsActive
      ? `¿Está seguro que quiere desactivar este item ${item.name}?`
      : `¿Está seguro que quiere activar este item ${item.name}?`;

    const newStatus = !newIsActive; // Toggle newStatus based on newIsActive
    console.log(newStatus);
    MySwal.fire({
      title: "Cambiar estado activo",
      text: confirmationMessage,
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Sí",
      cancelButtonText: "No",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await editLiveEventStatus(item.id, newStatus);
        MySwal.fire(
          "Éxito",
          `El estado activo de ${item.name} ha sido cambiado.`,
          "success"
        );
        refresh();
      }
    });
  };

  useEffect(() => {
    const getHome = () => {
      if (items) {
        const filteredItems = items.filter((item) =>
          item.tags.includes("home")
        );
        setHomeItems(filteredItems);
      }
    };
    getHome();
  }, [items]);
  useEffect(() => {
    const getLive = () => {
      if (items) {
        const filteredLive = items.filter((item) =>
          item.tags.includes("liveEvent")
        );
        setLiveItems(filteredLive);
      }
    };
    getLive();
  }, [items]);

  const refresh = () => {
    setRefreshing(true);

    getItemsFromFB();
    if (items) {
      const filteredItems = items.filter((item) => item.tags.includes("home"));
      setHomeItems(filteredItems);
    }
    if (items) {
      const filteredLive = items.filter((item) =>
        item.tags.includes("liveEvent")
      );
      setLiveItems(filteredLive);
    }

    setRefreshing(false);
  };

  const handleOpenEditModal = (item) => {
    setSelectedItem(item);
    setEditModalOpen(true);
  };
  const handleOpenLiveEditModal = (item) => {
    setSelectedLiveEvent(item);
    setLiveEditModalOpen(true);
  };

  const handleCloseEditModal = () => {
    setSelectedItem(null);
    setSelectedLiveEvent(null);
    setEditModalOpen(false);
  };
  const handleCloseLiveEditModal = () => {
    setSelectedItem(null);
    setSelectedLiveEvent(null);
    setSelectedLiveEvent(null);
    setLiveEditModalOpen(false);
  };

  const BodyComponent = useMemo(
    () => (tableProps) =>
      <CustomBodyWithSpinner loading={refreshing} {...tableProps} />,
    [refreshing]
  );

  const initDeleteProduct = (elementsToDelete) => {
    const rows = elementsToDelete.data;

    rows.forEach((element) => {
      const index = element.dataIndex;
      const productToDelete = items[index];
      dispatch(deleteItem(productToDelete.id, index));
    });
  };
  const columns = [
    {
      name: "name",
      label: "Nombre",
      filter: false,
    },
    {
      name: "imageURL",
      label: "Imagen",
      options: {
        customBodyRender: (value) => {
          return value ? <img src={value} alt="" width={"50px"} /> : "--";
        },
        filter: false,
      },
    },
    {
      name: "cdate",
      label: "Fecha",
      options: {
        customBodyRender: (value) => {
          if (typeof value === "object" && value.seconds && value.nanoseconds) {
            const date = new Date(
              value.seconds * 1000 + value.nanoseconds / 1000000
            );
            const formattedDate = date.toLocaleDateString();
            return formattedDate;
          } else {
            return "-";
          }
        },
        filter: false,
      },
    },
    {
      name: "id",
      label: "ID",
      options: {
        filter: false,
        display: false,
      },
    },

    {
      name: "isActive",
      label: "Estado",
      options: {
        filter: false,
        customBodyRender: (value, dataIndex) => {
          const data = homeItems[dataIndex.rowIndex]; // Use dataIndex.rowIndex
          // console.log("dataIndex:", dataIndex);
          // console.log("homeItems:", homeItems);
          // console.log("data:", data);
          // console.log("isActive:", value);

          return (
            <IconButton
              sx={{
                backgroundColor:
                  value === true
                    ? theme.palette.primary.main
                    : theme.palette.primary.secondary,

                borderRadius: "12px",
                maxWidth: 100,
              }}
              onClick={() => handleChangeIsActive(data, "home")}
            >
              {value ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    px: 1,
                  }}
                >
                  <Typography mr={1}>Activo</Typography>
                  <MdEdit />
                </Box>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    px: 1,
                  }}
                >
                  <Typography mr={1}>Inactivo</Typography>
                  <MdEdit />
                </Box>
              )}
            </IconButton>
          );
        },
      },
    },
    {
      name: "edit",
      label: "Editar",
      options: {
        empty: true,
        customBodyRenderLite: (dataIndex) => {
          const data = homeItems[dataIndex]; // Use homeItems instead of items

          // console.log("schedules:", schedules);
          // console.log("dataIndex:", dataIndex);
          // console.log("schedule:", schedule);

          const handleEdit = () => {
            handleOpenEditModal(data);
          };

          return (
            <IconButton
              sx={{ backgroundColor: theme.palette.primary.secondary }}
              onClick={handleEdit}
            >
              <MdEdit />
            </IconButton>
          );
        },
      },
      filter: false,
    },
    {
      name: "delete",
      label: "Eliminar",
      options: {
        empty: true,
        customBodyRenderLite: (dataIndex) => {
          const data = homeItems[dataIndex];

          const handleDelete = () => {
            Swal.fire({
              title: "Alerta",
              text: "¿Está seguro que desea eliminar este item?",
              icon: "warning",
              confirmButtonText: "Eliminar",
              showDenyButton: true,
              denyButtonText: "Volver",
            }).then(async (result) => {
              if (result.isConfirmed) {
                try {
                  await deleteItem(data.id, dataIndex);

                  Swal.fire("Item eliminado", "", "success");
                } catch (error) {
                  console.error("Error deleting item:", error);
                  Swal.fire("Error al eliminar el item", "", "error");
                }
              }
              refresh();
            });
          };

          return (
            <IconButton
              sx={{ backgroundColor: theme.palette.error.main }}
              onClick={handleDelete}
              disabled={!isSuperAdmin && !isAlmighty}
            >
              <MdDelete />
            </IconButton>
          );
        },
      },
      filter: false,
    },
  ];
  const columnsLiveEvent = [
    {
      name: "name",
      label: "Nombre",
      filter: false,
    },
    {
      name: "imageURL",
      label: "Imagen",
      options: {
        customBodyRender: (value) => {
          return value ? <img src={value} alt="" width={"50px"} /> : "--";
        },
        filter: false,
      },
    },
    {
      name: "cdate",
      label: "Fecha",
      options: {
        customBodyRender: (value) => {
          if (typeof value === "object" && value.seconds && value.nanoseconds) {
            const date = new Date(
              value.seconds * 1000 + value.nanoseconds / 1000000
            );
            const formattedDate = date.toLocaleDateString();
            return formattedDate;
          } else {
            return "-";
          }
        },
        filter: false,
      },
    },
    {
      name: "isActive",
      label: "Estado",
      options: {
        filter: false,
        customBodyRender: (value, dataIndex) => {
          const data = liveItems[dataIndex.rowIndex]; // Use dataIndex.rowIndex
          // console.log("dataIndex:", dataIndex);
          // console.log("homeItems:", homeItems);
          // console.log("data:", data);
          // console.log("isActive:", value);

          return (
            <IconButton
              sx={{
                backgroundColor:
                  value === true
                    ? theme.palette.primary.main
                    : theme.palette.primary.secondary,

                borderRadius: "12px",
                maxWidth: 100,
              }}
              onClick={() => handleChangeIsActive(data, "liveEvent")}
            >
              {value ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    px: 1,
                  }}
                >
                  <Typography mr={1}>Activo</Typography>
                  <MdEdit />
                </Box>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    px: 1,
                  }}
                >
                  <Typography mr={1}>Inactivo</Typography>
                  <MdEdit />
                </Box>
              )}
            </IconButton>
          );
        },
      },
    },
    {
      name: "edit",
      label: "Editar",
      options: {
        empty: true,
        customBodyRenderLite: (dataIndex) => {
          const data = liveItems[dataIndex]; // Use liveItems instead of items

          // console.log("schedules:", schedules);
          // console.log("dataIndex:", dataIndex);
          // console.log("schedule:", schedule);

          const handleEdit = () => {
            console.log(" edit button");
            handleOpenLiveEditModal(data);
          };

          return (
            <IconButton
              sx={{ backgroundColor: theme.palette.primary.secondary }}
              onClick={handleEdit}
            >
              <MdEdit />
            </IconButton>
          );
        },
      },
      filter: false,
    },

    {
      name: "delete",
      label: "Eliminar",
      options: {
        empty: true,
        customBodyRenderLite: (dataIndex) => {
          const data = liveItems[dataIndex];

          const handleDelete = () => {
            Swal.fire({
              title: "Alerta",
              text: "¿Está seguro que desea eliminar este item?",
              icon: "warning",
              confirmButtonText: "Eliminar",
              showDenyButton: true,
              denyButtonText: "Volver",
            }).then(async (result) => {
              if (result.isConfirmed) {
                try {
                  await deleteItem(data.id, dataIndex);
                  refresh();
                  Swal.fire("Item eliminado", "", "success");
                } catch (error) {
                  console.error("Error deleting item:", error);
                  Swal.fire("Error al eliminar el item", "", "error");
                }
              }
            });
          };

          return (
            <IconButton
              sx={{ backgroundColor: theme.palette.error.main }}
              onClick={handleDelete}
              disabled={!isSuperAdmin && !isAlmighty}
            >
              <MdDelete />
            </IconButton>
          );
        },
      },
      filter: false,
    },
  ];

  const options = {
    filter: true,
    selectableRows: "none",
    filterType: "dropdown",
    responsive: "standard",
    rowsPerPage: 10,
    pagination: {
      rowsPerPage: "Filas:",
      displayRows: "/",
    },
    onTableChange: (action, tableState) => {
      if (
        action === "filterChange" ||
        action === "resetFilters" ||
        action === "search"
      ) {
        const filteredItems = tableState.displayData.filter(
          (_, index) =>
            tableState.selectedRows.data.findIndex(
              ({ index: dataIndex }) => dataIndex === index
            ) === -1
        );
        const filteredHomeItems = filteredItems.filter((item) =>
          item.data[0].includes("home")
        );
        setHomeItems(filteredHomeItems);
      }
    },

    onRowsDelete: (rowsToDelete) => {
      // Show the delete confirmation alert using SweetAlert2
      Swal.fire({
        title: "Alerta",
        text: "¿Está seguro que desea eliminar?",
        icon: "warning",
        confirmButtonText: "Eliminar",
        showDenyButton: true,
        denyButtonText: "Volver",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            // Handle the delete operation for each row
            for (const row of rowsToDelete.data) {
              const dataIndex = row.index;
              const data = homeItems[dataIndex];
              console.log(data);
              // Use an asynchronous function to delete the item
              await deleteItem(data.id, dataIndex);
            }

            refresh();
            // Show success message after successful deletion
            Swal.fire("Flyer eliminado", "", "success");

            // Trigger a refresh to update the data after deletion
          } catch (error) {
            console.error("Error deleting item:", error);
            // Show an error message if something goes wrong with the delete operation
            Swal.fire("Error al eliminar el item", "", "error");
          }
        } else if (result.isDenied) {
          // Handle the "Volver" button action (if needed)
          console.log("Volver button clicked");
        }
      });

      // Prevent the default behavior of the MUIDataTable
      return false;
    },
  };
  const optionsLiveEvent = {
    filter: true,
    selectableRows: "none",
    filterType: "dropdown",
    responsive: "standard",
    rowsPerPage: 10,
    pagination: {
      rowsPerPage: "Filas:",
      displayRows: "/",
    },
    onTableChange: (action, tableState) => {
      if (
        action === "filterChange" ||
        action === "resetFilters" ||
        action === "search"
      ) {
        const filteredItems = tableState.displayData.filter(
          (_, index) =>
            tableState.selectedRows.data.findIndex(
              ({ index: dataIndex }) => dataIndex === index
            ) === -1
        );
        const liveItems = filteredItems.filter((item) =>
          item.data[0].includes("liveEvent")
        );
        setLiveItems(liveItems);
      }
    },
    onRowsDelete: (rowsToDelete) => {
      console.log(rowsToDelete);
      // Show the delete confirmation alert using SweetAlert2
      Swal.fire({
        title: "Alerta",
        text: "¿Está seguro que desea eliminar?",
        icon: "warning",
        confirmButtonText: "Eliminar",
        showDenyButton: true,
        denyButtonText: "Volver",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            // Handle the delete operation for each row
            for (const row of rowsToDelete.data) {
              const dataIndex = row.index;
              const data = liveItems[dataIndex];

              // Use an asynchronous function to delete the item
              await deleteItem(data.id, dataIndex);
            }
            refresh();

            // Show success message after successful deletion
            Swal.fire("Banner eliminado", "", "success");

            // Trigger a refresh to update the data after deletion
          } catch (error) {
            console.error("Error deleting item:", error);
            // Show an error message if something goes wrong with the delete operation
            Swal.fire("Error al eliminar el curso", "", "error");
          }
        }
      });

      // Prevent the default behavior of the MUIDataTable
      return false;
    },
  };

  if (items === null) {
    return <div>loading</div>;
  }

  return (
    <div id="tab-content">
      <Container maxWidth="xl" sx={{ p: "0px !important" }}>
        <div className="divider"></div>

        <Box sx={{ mt: 6 }}>
          <AddItems refresh={refresh} />
        </Box>
        <Box sx={styles.cardStyle} className="content theme-card">
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            TabIndicatorProps={{
              style: { backgroundColor: theme.palette.primary.main },
            }}
          >
            <Tab
              label="Banners Publicidad"
              style={
                tabValue === 0
                  ? { color: theme.palette.primary.main }
                  : { color: "white" }
              }
            />
            <Tab
              label="Banners Evento"
              style={
                tabValue === 1
                  ? { color: theme.palette.primary.main }
                  : { color: "white" }
              }
            />

            <div className="ml-auto mr-5  ">
              <IconButton color="success" onClick={() => refresh()}>
                <MdRefresh size={24} />
              </IconButton>
            </div>
          </Tabs>

          <div className="table-wrapper  ">
            {tabValue === 0 && (
              <MUIDataTable
                data={homeItems}
                columns={columns}
                options={options}
                components={{ TableBody: BodyComponent }}
              />
            )}
            {tabValue === 1 && (
              <MUIDataTable
                data={liveItems}
                columns={columnsLiveEvent}
                options={optionsLiveEvent}
                components={{ TableBody: BodyComponent }}
              />
            )}
          </div>
        </Box>
        {selectedItem !== null && (
          <ItemsEditModal
            refresh={refresh}
            item={selectedItem}
            open={editModalOpen}
            onClose={handleCloseEditModal}
          />
        )}
        {selectedLiveEvent !== null && (
          <LiveEventsEditModal
            refresh={refresh}
            item={selectedLiveEvent}
            open={liveeditModalOpen}
            onClose={handleCloseLiveEditModal}
          />
        )}
      </Container>
    </div>
  );
}
