import React, { useCallback, useState, useEffect, useRef } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useFormik } from "formik";
import * as yup from "yup";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styles } from "../../../assets/styles/styles";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { deleteVideo, getCourse, uploadVideo } from "../../store/actions/items";

import AddIcon from "@mui/icons-material/Add";
import IconButton from "@mui/material/IconButton";

import DeleteIcon from "@mui/icons-material/Delete";
import VideoDetails from "./VideoDetails";

const MySwal = withReactContent(Swal);

const AddVideo = ({ courseId }) => {
  const [editMode, setEditMode] = useState([]);
  const [courseData, setCourseData] = useState([]);
  const [videosFromFb, setVideosFromFb] = useState([]);
  const [loading, setLoading] = useState(true);
  const [expanded, setExpanded] = useState([]); // Initialize expanded state as an empty array
  const isFirstUse = useRef(true);
  const [isFirstAccordionExpanded, setIsFirstAccordionExpanded] =
    useState(false);

  const fetchCourseData = useCallback(async () => {
    try {
      setLoading(true);
      const { courseData, videos } = await getCourse(courseId);

      // Sort videos based on orderNumber from Firebase
      const sortedVideos = [...videos].sort(
        (a, b) => a.orderNumber - b.orderNumber
      );

      setCourseData(courseData);
      setVideosFromFb(sortedVideos);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }, [courseId]);

  useEffect(() => {
    setLoading(true);
    fetchCourseData();
    isFirstUse.current = false;
    setLoading(false);
  }, [fetchCourseData]);

  const handleAccordionChange = (index) => {
    const newExpanded = [...expanded];
    newExpanded[index] = !newExpanded[index];
    setExpanded(newExpanded);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      videoURL: "",
      category: "",
      cdate: "",
      duration: "",
      isGoldPlan: false,
      isMembersOnly: false,
      tags: [],
      usersCount: 0,
    },

    onSubmit: (values) => {
      SubmitForm(values, courseId);
    },
  });

  const refresh = async () => {
    setLoading(true);
    fetchCourseData();
  };

  const handleEditVideo = (index) => {
    setEditMode((prevEditMode) => {
      const newEditMode = [...prevEditMode];
      newEditMode[index] = !prevEditMode[index];
      return newEditMode;
    });
  };
  const handleDeleteVideo = async (index, video, event) => {
    event.stopPropagation(); // Stop event propagation

    Swal.fire({
      title: "Confirmar Eliminación",
      text: "¿Estás seguro de que deseas eliminar este video?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Eliminar",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          // Delete the video
          await deleteVideo(courseId, video.id);
          Swal.fire("¡Eliminado!", "El video ha sido eliminado.", "success");

          // Fetch updated videos data
          const { videos } = await getCourse(courseId);
          setVideosFromFb(videos);
        } catch (error) {
          console.error("Error deleting video:", error);
          Swal.fire("Error", "Ocurrió un error al eliminar el video.", "error");
        }
      }
    });
  };

  const SubmitForm = async (values, courseId, videoId = null) => {
    MySwal.fire({
      title: "¿Confirmar?",
      text: "¿Está seguro que quiere agregar este item?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Agregar",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        MySwal.fire({
          icon: "warning",
          title: "Guardando...",
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
          showConfirmButton: false,
        });
        try {
          const data = {
            name: values.name,
            videoURL: values.videoURL.includes("youtube")
              ? values.videoURL + "&"
              : values.videoURL,

            category: values.category,
            cdate: new Date(),
            duration: values.duration,
            tags: values.section,
          };

          await uploadVideo(courseId, data, videoId);

          const { videos } = await getCourse(courseId);
          setVideosFromFb(videos);
          MySwal.fire("Video Agregado!", "", "success");
        } catch (error) {
          console.error("Error submitting form:", error);
          Swal.fire("Error", "Ocurrió un error al agregar el video.", "error");
        }
      }
    });
  };

  if (loading) {
    return <div>loading</div>;
  }

  return (
    <form onSubmit={formik.handleSubmit} className="m-auto p-2 ">
      <Typography variant="h5" mb={5} mt={4}>
        Videos
      </Typography>

      {/* Add Video */}
      <Box sx={styles.innerCardStyle}>
        <Accordion
          expanded={isFirstAccordionExpanded}
          onChange={() =>
            setIsFirstAccordionExpanded(!isFirstAccordionExpanded)
          }
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
            sx={{
              backgroundColor: "#292929",
              borderRadius: isFirstAccordionExpanded ? "8px" : "8px",
              borderBottomRightRadius: isFirstAccordionExpanded ? "0px" : "8px",
              borderBottomLeftRadius: isFirstAccordionExpanded ? "0px" : "8px",
              borderTopLeftRadius: "8px",
              borderTopRightRadius: "8px",
              display: "flex",
              alignItems: "center",
              paddingLeft: 3,
              paddingRight: 3,
            }}
          >
            <Typography variant="subtitle1" color={"white"}>
              Agregar Video
            </Typography>
            <AddIcon sx={{ color: "white", width: "0.8em", ml: 0.4 }} />
          </AccordionSummary>
          <AccordionDetails
            sx={{
              backgroundColor: "#161618",
              borderBottomRightRadius: "8px",
              borderBottomLeftRadius: "8px",
              display: "flex",
              alignItems: "center",
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
              paddingLeft: 3,
              paddingRight: 3,
            }}
          >
            <Box sx={{ width: "75%" }}>
              {/* Fields for adding a new video */}
              <TextField
                margin="dense"
                variant="standard"
                fullWidth
                id="name"
                name="name"
                label="Nombre del video"
                type="text"
                value={formik.values.name}
                onChange={formik.handleChange}
              />

              <TextField
                margin="dense"
                variant="standard"
                fullWidth
                id="videoURL"
                name="videoURL"
                label="Video URL"
                type="text"
                value={formik.values.videoURL}
                onChange={formik.handleChange}
              />
              <TextField
                margin="dense"
                variant="standard"
                fullWidth
                id="description"
                name="description"
                label="Descripción"
                type="text"
                value={formik.values.description}
                onChange={formik.handleChange}
              />
              <TextField
                margin="dense"
                variant="standard"
                fullWidth
                id="section"
                name="section"
                label="Sección"
                type="text"
                value={formik.values.section}
                onChange={formik.handleChange}
              />
              <TextField
                margin="dense"
                variant="standard"
                fullWidth
                id="duration"
                name="duration"
                label="Duración del video"
                type="text"
                value={formik.values.duration}
                onChange={formik.handleChange}
              />
            </Box>
            <Box sx={{ width: "100%" }}>
              {/* Submit Button */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  marginTop: "20px",
                  marginBottom: "5px",
                }}
              >
                <Button
                  sx={{ width: "auto" }}
                  color="primary"
                  variant="contained"
                  fullWidth
                  type="submit"
                >
                  Crear
                </Button>
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>

      {/* Edit Video */}
      <Box sx={styles.innerCardStyle}>
        {videosFromFb.map((video, index) => (
          <Box sx={{ width: "100%" }}>
            <Accordion
              key={index}
              expanded={expanded[index]}
              onChange={() => handleAccordionChange(index)}
            >
              <AccordionSummary
                sx={{
                  backgroundColor: "#292929",
                  borderBottomRightRadius: expanded[index] ? "0px" : "8px",
                  borderBottomLeftRadius: expanded[index] ? "0px" : "8px",
                  borderTopLeftRadius: "8px",
                  borderTopRightRadius: "8px",
                  display: "flex",
                  alignItems: "center",

                  paddingLeft: 3,
                  paddingRight: 3,
                }}
              >
                <Typography variant="subtitle1" color={"white"} mr={4}>
                  {video.tags}
                </Typography>
                <Typography variant="subtitle1" color={"white"} mr={4}>
                  {video.name}
                </Typography>
                <Typography variant="subtitle1" color={"white"}>
                  #{video.orderNumber}
                </Typography>

                <Box sx={{ marginLeft: "auto" }}>
                  <IconButton
                    aria-label="delete"
                    size="small"
                    onClick={(event) => handleDeleteVideo(index, video, event)}
                    sx={{ boxShadow: "none", marginLeft: 2, color: "white" }}
                  >
                    <DeleteIcon />
                  </IconButton>
                  <IconButton
                    aria-label="expand"
                    size="small"
                    sx={{ boxShadow: "none", marginLeft: 2, color: "white" }}
                  >
                    <ExpandMoreIcon />
                  </IconButton>
                </Box>
                {/* Edit and Delete Icons */}
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  backgroundColor: "#161618",
                  borderBottomRightRadius: "8px",
                  borderBottomLeftRadius: "8px",
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                  paddingLeft: 3,
                  paddingRight: 3,
                }}
              >
                <VideoDetails
                  data={video}
                  courseId={courseId}
                  refresh={refresh}
                />
              </AccordionDetails>
            </Accordion>
          </Box>
        ))}
      </Box>
    </form>
  );
};

export default AddVideo;
