import { minHeight } from "@mui/system";
import { styles } from "./styles";

const drawerStyles = {
background:'green'
};





export const sidebarStyles = {
  drawerStyles:drawerStyles,

};
