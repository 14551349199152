import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Dashboard from "../views/Dashboard";
import Login from "./../views/auth/Login";
import SidebarLayout from "./../components/SidebarLayout";
import { PrivateRoute } from "./PrivateRoute";
import { useDispatch, useSelector } from "react-redux";
import { auth } from "../../firebase/firebase-config";
import {
  logoutUser,
  setIsLoadingAuth,
  signInWithEmail,
} from "../store/actions/auth";
import { Typography } from "@mui/material";
import ItemsView from "./../views/ItemsView";
import AcademyView from "./../views/AcademyView";
import NotificationsView from "../views/NotificationsView";
import SchedulesView from "./../views/SchedulesView";
import SignalsView from "./../views/SignalsView";
import UsersView from "./../views/UsersView";
import WeeklyNotificationsView from "./../views/WeeKlyNotificationsView";
import RequestsView from "../views/RequestsView";

function AppRoutes() {
  const dispatch = useDispatch();
  const history = useLocation();
  const { loggedIn, isLoadingAuth } = useSelector((state) => state.auth);
  const lastUrl = localStorage.getItem("lastUrl") || "/home";
  useEffect(() => {
    dispatch(setIsLoadingAuth(true));
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        const metadata = await user.getIdTokenResult();
        // console.log(metadata);
        if (
          metadata.claims.superadmin ||
          metadata.claims.admin ||
          metadata.claims.almighty
        ) {
          dispatch(signInWithEmail(user.toJSON(), metadata.claims));
          sessionStorage.setItem("user", JSON.stringify(user.toJSON()));
        } else {
          sessionStorage.removeItem("user");
          dispatch(logoutUser());
        }

        dispatch(setIsLoadingAuth(false));
      } else {
        console.log("no hay");
        sessionStorage.removeItem("user");
        dispatch(logoutUser());
        dispatch(setIsLoadingAuth(false));
      }
    });
  }, [dispatch]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.setItem("lastUrl", history.pathname);
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [history]);

  if (isLoadingAuth) {
    return (
      <div id="login">
        <div className="login-wrapper ">
          <div className="align">
            <Typography component="h1" variant="h5">
              Cargando ...
            </Typography>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <Routes>
        <Route path="/" element={<SidebarLayout />}>
          <Route
            path=""
            element={
              loggedIn ? (
                <Navigate replace to={lastUrl} />
              ) : (
                <Navigate to="/auth/login" />
              )
            }
          />

          <Route
            path="/*"
            element={
              <PrivateRoute isLoggedIn={loggedIn}>
                <Routes>
                  <Route
                    path="/auth/login"
                    element={
                      <Navigate
                        replace
                        to={lastUrl === "/auth/login" ? "/home" : lastUrl}
                      />
                    }
                  />
                  <Route index path="/home" element={<Dashboard />} />
                  <Route path="/items" element={<ItemsView />} />
                  <Route path="/academy" element={<AcademyView />} />
                  <Route
                    path="/notifications"
                    element={<NotificationsView />}
                  />
                  <Route
                    path="/weekly-notifications"
                    element={<WeeklyNotificationsView />}
                  />
                  <Route path="/schedules" element={<SchedulesView />} />
                  <Route path="/users" element={<UsersView />} />
                  <Route path="/requests" element={<RequestsView />} />
                  <Route path="/signals" element={<SignalsView />} />
                </Routes>
              </PrivateRoute>
            }
          />
        </Route>

        {!loggedIn ? (
          <Route Route path="/auth">
            <Route path="" element={<Navigate replace to="login" />} />
            <Route path="login" element={<Login />} />
          </Route>
        ) : null}
      </Routes>
    </>
  );
}

export default AppRoutes;
