import React, { useState, useEffect, useCallback } from "react";
import { Grid, AccordionSummary, Typography, Box } from "@mui/material";
import { getChangesFromRequest } from "../../store/actions/users";

const ChangesSummarySmall = ({ id }) => {
  const [lastObservation, setLastObservation] = useState(null);

  const fetchObservations = useCallback(async () => {
    const observationsData = await getChangesFromRequest(id);
    // Sort observations by cdate
    const sortedObservations = observationsData.sort(
      (a, b) => new Date(b.cdate) - new Date(a.cdate)
    );
    // Get the last observation
    const last = sortedObservations[0];
    setLastObservation(last);
  }, [id]);

  useEffect(() => {
    fetchObservations();
  }, [fetchObservations]);

  return (
    <Grid container item spacing={2} md={12}>
      <Grid item xs={12}>
        {lastObservation && (
          <Box sx={{ backgroundColor: "#4B4B4B", borderRadius: "12px", p: 1 }}>
            <Typography>
              {`${lastObservation.admin} - ${lastObservation.cdate
                .toDate()
                .toLocaleString("es-AR")}`}{" "}
            </Typography>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default ChangesSummarySmall;
