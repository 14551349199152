import jwtDecode from "jwt-decode";

import React from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

import MenuItem from "@mui/material/MenuItem";

import { useFormik } from "formik";
import * as yup from "yup";
import Box from "@mui/material/Box";

import { styles } from "../../../assets/styles/styles";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";

import {
  checkIfUserExists,
  createUser,
  deleteRequest,
  editUser,
  rejectUserEp,
  updateRequest,
} from "../../store/actions/users";
import { Checkbox, FormControlLabel, useTheme } from "@mui/material";
import { Close } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { refreshToken } from "../../store/actions/auth";
import ChangesSummary from "./ChangesSummary";
const MySwal = withReactContent(Swal);
const tier = [1, 2, 3];

const RequestsModal = ({ onClose, open, item, refresh, admin }) => {
  const theme = useTheme();
  const { idToken } = useSelector((state) => state.auth);

  const tiers = [
    {
      value: 1,
      label: "Nivel Básico",
    },
    {
      value: 2,
      label: "Nivel Intermedio",
    },
    {
      value: 3,
      label: "Nivel Avanzado",
    },
  ];
  const defaultTierValue =
    item.isActive && !item.isGoldPlan
      ? 2
      : item.isGoldPlan && item.isActive
      ? 3
      : 1;

  const validationSchema = yup.object({
    email: yup
      .string("Ingrese el título")
      .required("Campo Obligatorio")
      .email("Ingrese un correo electrónico válido"),
    name: yup.string("Campo Obligatorio").required("Campo Obligatorio"),

    brokerId: yup
      .string()
      .required("Campo obligatorio")
      .test(
        "startsWith18",
        "El ID del broker debe empezar con '18'",
        (value) => value && value.startsWith("18")
      ),
  });

  const formik = useFormik({
    initialValues: {
      name: item.name ?? "",
      lastname: item.lastname ?? "",
      email: item.email ?? "",
      selectedAnswer: item.selectedAnswer ?? "",
      reason: item.reason ?? "",
      lastname2: item.lastname2 ?? "",
      phone: item.phone ?? "",
      discordId: item.discordId ?? "",
      brokerId: item.brokerId ?? "",
      tier: defaultTierValue,
      discordActive: item.discordActive || false,
      isActive: item.isActive || false,
      isGoldPlan: item.isGoldplan || false,
    },
    validateOnMount: true,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      SubmitForm(values);
    },
  });

  const SubmitForm = async (values) => {
    const isActive = values.tier === 2 || values.tier === 3;
    const isGoldPlan = values.tier === 3;
    const reason = item?.reason;
    MySwal.fire({
      title: "¿Confirm?",
      text: "¿Está seguro que quiere aceptar esta solicitud?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        MySwal.fire({
          icon: "warning",
          title: "Guardando...",
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
          showConfirmButton: false,
        });

        const userData = {
          name: values.name,
          lastname: values.lastname,
          lastname2: values.lastname2,
          email: values.email,

          phone: values.phone,
          discordId: values.discordId,
          brokerId: values.brokerId,
          tier: values.tier,
        };

        const userCheckResult = await checkIfUserExists(values.brokerId);
        if (userCheckResult.exists && !userCheckResult.isDisabled) {
          MySwal.fire(
            "Ya existe un usuario con el mismo Broker Id",
            "",
            "error"
          );
        } else {
          try {
            if (isValidToken(idToken)) {
              const res = await createUser(userData, idToken);

              if (res.ok) {
                await updateRequest(item.id, "accepted", admin.name, reason);
                MySwal.fire(
                  `Usuario ${
                    values.name + " " + values.lastname
                  } creado con el correo ${values.email}  `,
                  "",
                  "success"
                );
                onClose();
              } else if (res.error.code === 11) {
                MySwal.fire("El correo ya existe", "", "error");
              }
            } else {
              const newToken = await refreshToken();
              const res = await createUser(userData, newToken);

              if (res.ok) {
                await updateRequest(item.id, "accepted", admin.name, reason);
                MySwal.fire(
                  `Usuario ${
                    values.name + " " + values.lastname
                  } creado con el correo ${values.email} `,
                  "",
                  "success"
                );
                onClose();
              } else if (res.error.code === 11) {
                MySwal.fire("El correo ya existe", "", "error");
              }
            }
          } catch (error) {
            console.error("Error creating user:", error);

            MySwal.fire("Hubo un error al guardar el usuario", "", "error");
          }
        }
        refresh();
        resetForm();
      } else if (result.dismiss === "cancel") {
        MySwal.fire("Cambios no guardados", "", "info");
        refresh();
      }
    });
  };
  const handleReject = () => {
    const reason = item?.reason;
    Swal.fire({
      title: "Alerta",
      text: `¿Está seguro que desea denegar la solicitud?`,
      confirmButtonText: "Eliminar",
      showDenyButton: true,
      denyButtonText: "Volver",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const loadingSwal = MySwal.fire({
          title: "Guardando...",
          showConfirmButton: false,
          allowOutsideClick: false,
          didOpen: () => {
            MySwal.showLoading();
          },
        });
        try {
          if (isValidToken(idToken)) {
            const res = await rejectUserEp(formik.values.email, idToken);

            if (res.ok) {
              await updateRequest(item.id, "rejected", admin.name, reason);
              MySwal.fire(`Solicitud Rechazada `, "", "success");

              onClose();
            } else if (res.error.code === 11) {
              MySwal.fire("Hubo un  error", "", "error");
            }
          } else {
            const newToken = await refreshToken();
            const res = await rejectUserEp(formik.values.email, newToken);

            if (res.ok) {
              await updateRequest(item.id, "rejected", admin.name, reason);
              MySwal.fire(`Solicitud Rechazada `, "", "success");

              onClose();
            } else if (res.error.code === 11) {
              MySwal.fire("Hubo un  error", "", "error");
            }
          }

          MySwal.fire("Solicitud Denegada!", "", "success");
          loadingSwal.close();
        } catch (error) {
          console.error("Error deleting request:", error);
          loadingSwal.close();
          MySwal.fire("Hubo un error al rechazar la solicitud", "", "error");
        }
      }
    });
  };
  const handleDelete = () => {
    Swal.fire({
      title: "Alerta",
      text: `¿Está seguro que desea eliminar la solicitud?`,
      confirmButtonText: "Eliminar",
      showDenyButton: true,
      denyButtonText: "Volver",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const loadingSwal = MySwal.fire({
          title: "Guardando...",
          showConfirmButton: false,
          allowOutsideClick: false,
          didOpen: () => {
            MySwal.showLoading();
          },
        });
        try {
          await deleteRequest(item.id);

          MySwal.fire("Solicitud Eliminada!", "", "success");
          loadingSwal.close();
          refresh();
        } catch (error) {
          console.error("Error deleting request:", error);
          loadingSwal.close();
          MySwal.fire("Hubo un error al eliminar la solicitud", "", "error");
        }
      }
    });
  };
  const isValidToken = (token) => {
    if (!token) {
      return false; // If token is missing or undefined, it is not valid
    }
    const decodedToken = jwtDecode(token);
    const currentTime = Math.floor(Date.now() / 1000);

    return currentTime < decodedToken.exp;
  };
  const resetForm = () => {
    formik.resetForm();
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          backgroundColor: theme.palette.secondary.secondary,
          marginTop: 100,
          backgroundImage: "none",
          overflowX: "hidden",
        },
      }}
      sx={{ zIndex: 1251, width: "100%" }}
      fullWidth={true}
    >
      <DialogTitle sx={{ color: theme.palette.primary.main }}>
        Solicitud de Registro
        <Box sx={[styles.closeButton]} onClick={onClose}>
          <Close />
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid container alignItems="center" justifyContent="center">
          <Grid item md={12} sm={12} className="mb-4">
            <Box sx={styles.cardStyle} className=" add-media-card mb-4 ">
              <form onSubmit={formik.handleSubmit} className="m-auto p-2 w-100">
                <Box sx={styles.innerCardStyle}>
                  <TextField
                    margin="dense"
                    variant="standard"
                    fullWidth
                    id="reason"
                    name="reason"
                    type="text"
                    label="Motivo"
                    value={formik.values.reason}
                    contentEditable={false}
                  />
                  <TextField
                    margin="dense"
                    variant="standard"
                    fullWidth
                    id="name"
                    name="name"
                    type="text"
                    label="Nombre"
                    value={formik.values.name}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                  <TextField
                    margin="dense"
                    variant="standard"
                    fullWidth
                    id="lastname"
                    name="lastname"
                    type="text"
                    label="Apellido"
                    value={formik.values.lastname}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.lastname && Boolean(formik.errors.lastname)
                    }
                    helperText={
                      formik.touched.lastname && formik.errors.lastname
                    }
                  />
                  <TextField
                    margin="dense"
                    variant="standard"
                    fullWidth
                    id="lastname2"
                    name="lastname2"
                    type="text"
                    label="Segundo Apellido"
                    value={formik.values.lastname2}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.lastname2 &&
                      Boolean(formik.errors.lastname2)
                    }
                    helperText={
                      formik.touched.lastname2 && formik.errors.lastname2
                    }
                  />
                  <TextField
                    margin="dense"
                    variant="standard"
                    fullWidth
                    id="email"
                    name="email"
                    type="text"
                    label="Correo Electrónico"
                    value={formik.values.email}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />

                  <TextField
                    margin="dense"
                    variant="standard"
                    fullWidth
                    id="phone"
                    name="phone"
                    type="phone"
                    label="Telêfono"
                    value={formik.values.phone}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                    helperText={formik.touched.phone && formik.errors.phone}
                  />
                  <TextField
                    margin="dense"
                    variant="standard"
                    fullWidth
                    id="discordId"
                    name="discordId"
                    type="discordId"
                    label="Discord ID"
                    value={formik.values.discordId}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.phone && Boolean(formik.errors.discordId)
                    }
                    helperText={formik.touched.phone && formik.errors.discordId}
                  />
                  <TextField
                    margin="dense"
                    variant="standard"
                    fullWidth
                    id="brokerId"
                    name="brokerId"
                    type="brokerId"
                    label="Broker ID"
                    value={formik.values.brokerId}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.phone && Boolean(formik.errors.brokerId)
                    }
                    helperText={formik.touched.phone && formik.errors.brokerId}
                  />
                  <Grid
                    container
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      flexDirection: "row",
                    }}
                  >
                    <FormControl
                      component="fieldset"
                      sx={{
                        flexDirection: "row",
                        marginBottom: "15px",
                        mr: 1,
                      }}
                    >
                      <FormControlLabel
                        sx={{
                          marginLeft: "0px !important",
                          flexDirection: "row",
                        }}
                        value="discordActive"
                        label="Discord Activo"
                        labelPlacement="start"
                        control={
                          <Checkbox
                            name="discordActive"
                            checked={formik.values.discordActive}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            inputProps={{ "aria-label": "primary checkbox" }}
                          />
                        }
                      />
                    </FormControl>
                  </Grid>
                  <FormControl
                    sx={{ marginTop: 2 }}
                    fullWidth
                    error={formik.touched.tier && Boolean(formik.errors.tier)}
                  >
                    <TextField
                      select
                      id="tier"
                      name="tier"
                      label="Tier"
                      value={formik.values.tier}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      error={formik.touched.tier && Boolean(formik.errors.tier)}
                      helperText={formik.touched.tier && formik.errors.tier}
                    >
                      {tiers.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    {formik.touched.tier && formik.errors.tier && (
                      <FormHelperText>{formik.errors.tier}</FormHelperText>
                    )}
                  </FormControl>

                  <Grid
                    container
                    item
                    md={8}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignContent: "center",
                      marginTop: "20px",
                      marginBottom: "5px",
                    }}
                    columnGap={2}
                  >
                    {item.status !== "accepted" && (
                      <Grid item md={3}>
                        <Button
                          className="w-100"
                          color="primary"
                          variant="contained"
                          fullWidth
                          type="submit"
                          disabled={!formik.isValid}
                        >
                          Aceptar
                        </Button>
                      </Grid>
                    )}
                    {item.status !== "rejected" && (
                      <Grid item md={3}>
                        <Button
                          className="w-100"
                          color={"warning"}
                          variant="contained"
                          fullWidth
                          onClick={handleReject}
                        >
                          Rechazar
                        </Button>
                      </Grid>
                    )}
                    <Grid item md={3}>
                      <Button
                        className="w-100"
                        color="error"
                        variant="contained"
                        fullWidth
                        onClick={handleDelete}
                      >
                        Eliminar
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </form>
            </Box>
            {item.status !== "pending" && <ChangesSummary item={item} />}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default RequestsModal;
