import React, { useState, useCallback, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Dialog from "@mui/material/Dialog";
import AccountCircle from "@mui/icons-material/AccountCircle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { MdEdit } from "react-icons/md";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import { styles } from "../../../assets/styles/styles";
import { Field, useFormik } from "formik";
import * as yup from "yup";
import IconButton from "@mui/material/IconButton";
import Dragndrop from "../dragndrop/Dragndrop";
import Preview from "../dragndrop/Preview";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);
const homeItems = [
  {
    value: "events",
    label: "Eventos",
  },

  {
    value: "services",
    label: "Servicios",
  },
  {
    value: "success",
    label: "Casos de Éxito",
  },
];

const EditItemModal = (props) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [value, setValue] = useState("1");
  const [checked, setChecked] = useState(false);
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const handleCheck = (event) => {
    setChecked(event.target.checked);
  };

  //Formik
  // const validationSchema = yup.object({
  //   name: yup.string("Ingrese el nombre").required("Campo Obligatorio"),
  //   description: yup
  //     .string("Ingrese una descripción")
  //     .required("Campo Obligatorio"),
  //   files: yup.mixed().required(),
  // });

  //Formik
  const validationSchema = yup.object({
    name: yup.string("Ingrese el nombre").required("Campo Obligatorio"),
    description: yup
      .string("Ingrese una descripción")
      .required("Campo Obligatorio"),

    files: yup.mixed().required(),
  });

  const formik = useFormik({
    initialValues: {
      itemSection: "events",
      name: "",
      description: "",
      link: "",
      files: null,
      recommended: false,
    },
    validationSchema: validationSchema,

    onSubmit: (values) => {
      SubmitForm(values);

      // console.log({
      //   files: values.files.map((file) => ({
      //     fileName: file.name,
      //     type: file.type,
      //     size: `${file.size} bytes`,
      //   })),
      // });
    },
  });

  const SubmitForm = (values) => {
    MySwal.fire({
      customClass: {
        container: "my-swal",
      },
      title: "¿Guardar Cambios?",

      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Modificar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        MySwal.fire("Item Modificado!", "", "success");
        console.log(values);
        setOpen(false);
      }
    });
  };
  //Dropzone
  const [files, setFiles] = useState([]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
    [files]
  );
  // const removeFile = (file) => () => {
  //   const newFiles = [...files];
  //   newFiles.splice(newFiles.indexOf(file), 1);
  //   setFiles(newFiles);
  // };

  const removeAll = () => {
    setFiles([]);
  };

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks
    files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [files]);
  return (
    <>
      <IconButton variant="contained" color="success" onClick={handleClickOpen}>
        <MdEdit />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            backgroundColor: styles.grey[0],
            color: styles.darkGreen,
            boxShadow: "5px 5px 15px rgba(117, 117, 117, 0.082)",
          },
        }}
      >
        <DialogTitle sx={{ fontWeight: "800" }}>Editar Item</DialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent>
            <DialogContentText></DialogContentText>
            <TextField
              fullWidth
              id="itemSection"
              name="itemSection"
              select
              label="Seleccione"
              value={formik.values.itemSection}
              onChange={formik.handleChange}
              helperText="Escoja la sección que desea cambiar"
              SelectProps={{
                MenuProps: {
                  style: {
                    maxWidth: 0,
                    maxHeight: 300,
                    position: "absolute",
                    getContentAnchorEl: null,
                    autoFocus: true,
                  },
                  disableScrollLock: true,
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="center">
                    {/* <MdEdit /> */}
                  </InputAdornment>
                ),
              }}
              variant="standard"
            >
              {homeItems.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <div className="center">
              <TextField
                margin="dense"
                variant="standard"
                // sx={{width:"85%"}}
                fullWidth
                id="name"
                name="name"
                label="Nombre"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="end"></InputAdornment>
                  ),
                }}
              />
              <MdEdit />
            </div>
            <div className="center">
              <TextField
                margin="dense"
                variant="standard"
                fullWidth
                id="description"
                name="description"
                label="Descripción"
                type="description"
                value={formik.values.description}
                onChange={formik.handleChange}
                error={
                  formik.touched.description &&
                  Boolean(formik.errors.description)
                }
                helperText={
                  formik.touched.description && formik.errors.description
                }
              />
              <MdEdit />
            </div>
            <div className="center">
              <TextField
                margin="dense"
                variant="standard"
                fullWidth
                id="link"
                name="link"
                label="link"
                type="text"
                value={formik.values.link}
                onChange={formik.handleChange}
                error={formik.touched.link && Boolean(formik.errors.link)}
                helperText={formik.touched.link && formik.errors.link}
              />
              <MdEdit />
            </div>
            <Box item md={4} sx={styles.uploadBoxStyle}>
              {files.length === 0 ? (
                <div className="w-75 center m-auto">
                  <Dragndrop onDrop={onDrop} accept={"image/*"} maxFiles={1} />
                </div>
              ) : (
                <Preview files={files} removeAll={removeAll} />
              )}
            </Box>
            <FormControl
              component="fieldset"
              sx={{
                marginLeft: "0px !important",
                flexDirection: "row",
                marginBottom: "15px",
              }}
            >
              <FormControlLabel
                sx={{ marginLeft: "0px !important", flexDirection: "row" }}
                value="recommended"
                label="Recomendados"
                labelPlacement="start"
                control={
                  <Checkbox
                    name="recommended"
                    checked={formik.values.recommended}
                    onChange={formik.handleChange}
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                }
              />
            </FormControl>
            <FormHelperText sx={{ marginTop: "-24px !important" }}>
              Seleccione si quiere que la publicación aparezca en la seccion de
              recomendados
            </FormHelperText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="error" onClick={handleClose}>
              Volver
            </Button>
            <Button
              variant="contained"
              color="success"
              type="submit"
              onClick={formik.handleSubmit}
            >
              Guardar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default EditItemModal;
