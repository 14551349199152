import Swal from "sweetalert2";
import { db } from "../../../firebase/firebase-config";
import { ADMIN_API_URL } from "../../variables/globals";
export const SET_SCHEDULE = "SET_SIGNALS";
export const SET_IS_LOADING = "SET_IS_LOADING";

// export const getSchedule = async () => {
//   const querySnapshot = await db()
//     .collection("schedules")
//     .doc("ttKRoUGCbIdXkd8iPyDd")
//     .collection("events")
//     .get();

//   if (querySnapshot.empty) {
//     return [];
//   }

//   return querySnapshot.docs.map((doc) => {
//     return { id: doc.id, ...doc.data() };
//   });
// };

export const getAllSchedules = () => {
    return (dispatch) => {
      const schedules = [];
      dispatch(isLoading(true));
       db.collection("schedules")
    .doc("ttKRoUGCbIdXkd8iPyDd")
    .collection("events")
    .get().then(async (itemsFromFs) => {
          console.log("success");
          itemsFromFs.docs.forEach((item) =>
          schedules.push({ id: item.id, ...item.data() })
          );
          dispatch(getSchedule(schedules));
          dispatch(isLoading(false));
        })
        .catch((err) => {
          console.log(err);
          dispatch(isLoading(false));
        });
    };
  };
  export const deleteSchedule = (itemId) => {
    return (dispatch) => {
      try {
        db.collection("schedules").doc('ttKRoUGCbIdXkd8iPyDd').collection("events").doc(itemId).delete();
      } catch (error) {
        console.log(error);
      }
    };
  };
  export const callDeleteAlertSchedule = (
    elementsToDelete,
    deleteElementFirebase,
    handleRefresh,
    successMsg
    //deleteElement
  ) => {
    Swal.fire({
      title: "Alerta",
      text: "¿Esta seguro que desea eliminar?",
      icon: "warning",
      confirmButtonText: "Aceptar",
      showDenyButton: true,
      denyButtonText: "Volver",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteElementFirebase(elementsToDelete);
        //deleteElement()
        Swal.fire(successMsg, "", "success");
        if (handleRefresh) {
          handleRefresh();
        }
      }
    });
  };

export const getSchedule = (schedules) => ({
  type: SET_SCHEDULE,
  payload: {
    schedules,
  },
});
export const isLoading = (isLoading) => ({
  type: SET_IS_LOADING,
  payload: {
    isLoading,
  },
});
