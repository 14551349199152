import React from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

import MenuItem from "@mui/material/MenuItem";

import { useFormik } from "formik";
import * as yup from "yup";
import Box from "@mui/material/Box";

import { styles } from "../../../assets/styles/styles";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";

import { editUser, writeHistory } from "../../store/actions/users";
import { Checkbox, FormControlLabel, useTheme } from "@mui/material";
import { Close } from "@mui/icons-material";
import { useSelector } from "react-redux";
const MySwal = withReactContent(Swal);
const tier = [1, 2, 3];

const UsersEditModal = ({ onClose, open, item, refresh }) => {
  const theme = useTheme();
  const { user } = useSelector((state) => state.auth);

  const tiers = [
    {
      value: 1,
      label: "Nivel Básico",
    },
    {
      value: 2,
      label: "Nivel Intermedio",
    },
    {
      value: 3,
      label: "Nivel Avanzado",
    },
  ];
  const defaultTierValue =
    item.isActive && !item.isGoldPlan
      ? 2
      : item.isGoldPlan && item.isActive
      ? 3
      : 1;
  const formik = useFormik({
    initialValues: {
      name: item.name ?? "",
      lastname: item.lastname ?? "",
      lastname2: item.lastname2 ?? "",
      phone: item.phone ?? "",
      discordId: item.discordId ?? "",
      brokerId: item.brokerId ?? "",
      tier: defaultTierValue,
      discordActive: item.discordActive || false,
      isActive: item.isActive || false,
      isGoldPlan: item.isGoldplan || false,
    },
    enableReinitialize: true,

    onSubmit: (values) => {
      SubmitForm(values);
    },
  });

  const SubmitForm = async (values) => {
    const isActive = values.tier === 2 || values.tier === 3;
    const isGoldPlan = values.tier === 3;

    // Check if isActive or isGoldPlan has changed
    const isActiveChanged = item.isActive !== isActive;
    const isGoldPlanChanged = item.isGoldPlan !== isGoldPlan;

    // Prepare data for the editUser function
    const userData = {
      id: item.id,
      name: values.name,
      lastname: values.lastname,
      lastname2: values.lastname2,
      phone: values.phone,
      discordId: values.discordId,
      discordActive: values.discordActive,
      brokerId: values.brokerId,
      isActive: isActive ?? false,
      isGoldPlan: isGoldPlan ?? false,
    };

    MySwal.fire({
      title: "¿Confirm?",
      text: "¿Está seguro que quiere editar este usuario?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Editar",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        MySwal.fire({
          icon: "warning",
          title: "Guardando...",
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
          showConfirmButton: false,
        });
        try {
          // Update user data
          await editUser(userData, item);

          // Write history if isActive or isGoldPlan changed
          if (isActiveChanged || isGoldPlanChanged) {
            await writeHistory(
              values,
              isActiveChanged,
              isGoldPlanChanged,
              item,
              user
            );
          }

          MySwal.fire("Cambios Guardados!", "", "success");
          refresh();
          onClose();
        } catch (error) {
          MySwal.fire("Hubo un error al guardar los cambios", "", "error");
          onClose();
        }
      } else if (result.isDenied) {
        onClose();
      }
    });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          backgroundColor: theme.palette.secondary.secondary,
          marginTop: 100,
          backgroundImage: "none",
          overflowX: "hidden",
        },
      }}
      sx={{ zIndex: 1251, width: "100%" }}
      fullWidth={true}
    >
      <DialogTitle sx={{ color: theme.palette.primary.main }}>
        Editar Usuario
        <Box sx={[styles.closeButton]} onClick={onClose}>
          <Close />
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid container alignItems="center" justifyContent="center">
          <Grid item md={12} sm={12} className="mb-4">
            <Box sx={styles.cardStyle} className=" add-media-card mb-4 ">
              <form onSubmit={formik.handleSubmit} className="m-auto p-2 w-100">
                <Box sx={styles.innerCardStyle}>
                  <TextField
                    margin="dense"
                    variant="standard"
                    fullWidth
                    id="name"
                    name="name"
                    type="text"
                    label="Nombre"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                  <TextField
                    margin="dense"
                    variant="standard"
                    fullWidth
                    id="lastname"
                    name="lastname"
                    type="text"
                    label="Apellido"
                    value={formik.values.lastname}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.lastname && Boolean(formik.errors.lastname)
                    }
                    helperText={
                      formik.touched.lastname && formik.errors.lastname
                    }
                  />
                  <TextField
                    margin="dense"
                    variant="standard"
                    fullWidth
                    id="lastname2"
                    name="lastname2"
                    type="text"
                    label="Segundo Apellido"
                    value={formik.values.lastname2}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.lastname2 &&
                      Boolean(formik.errors.lastname2)
                    }
                    helperText={
                      formik.touched.lastname2 && formik.errors.lastname2
                    }
                  />

                  <TextField
                    margin="dense"
                    variant="standard"
                    fullWidth
                    id="phone"
                    name="phone"
                    type="phone"
                    label="Telêfono"
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                    helperText={formik.touched.phone && formik.errors.phone}
                  />
                  <TextField
                    margin="dense"
                    variant="standard"
                    fullWidth
                    id="discordId"
                    name="discordId"
                    type="discordId"
                    label="Discord ID"
                    value={formik.values.discordId}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.phone && Boolean(formik.errors.discordId)
                    }
                    helperText={formik.touched.phone && formik.errors.discordId}
                  />
                  <TextField
                    margin="dense"
                    variant="standard"
                    fullWidth
                    id="brokerId"
                    name="brokerId"
                    type="brokerId"
                    label="Broker ID"
                    value={formik.values.brokerId}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.phone && Boolean(formik.errors.brokerId)
                    }
                    helperText={formik.touched.phone && formik.errors.brokerId}
                  />
                  <Grid
                    container
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      flexDirection: "row",
                    }}
                  >
                    <FormControl
                      component="fieldset"
                      sx={{
                        flexDirection: "row",
                        marginBottom: "15px",
                        mr: 1,
                      }}
                    >
                      <FormControlLabel
                        sx={{
                          marginLeft: "0px !important",
                          flexDirection: "row",
                        }}
                        value="discordActive"
                        label="Discord Activo"
                        labelPlacement="start"
                        control={
                          <Checkbox
                            name="discordActive"
                            checked={formik.values.discordActive}
                            onChange={formik.handleChange}
                            inputProps={{ "aria-label": "primary checkbox" }}
                          />
                        }
                      />
                    </FormControl>
                  </Grid>
                  <FormControl
                    sx={{ marginTop: 2 }}
                    fullWidth
                    error={formik.touched.tier && Boolean(formik.errors.tier)}
                  >
                    <TextField
                      select
                      id="tier"
                      name="tier"
                      label="Tier"
                      value={formik.values.tier}
                      onChange={formik.handleChange}
                      error={formik.touched.tier && Boolean(formik.errors.tier)}
                      helperText={formik.touched.tier && formik.errors.tier}
                    >
                      {tiers.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    {formik.touched.tier && formik.errors.tier && (
                      <FormHelperText>{formik.errors.tier}</FormHelperText>
                    )}
                  </FormControl>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                      marginTop: "20px",
                      marginBottom: "5px",
                    }}
                  >
                    <Button
                      className="w-100"
                      color="primary"
                      variant="contained"
                      fullWidth
                      type="submit"
                      disabled={!formik.isValid}
                    >
                      Guardar Cambios
                    </Button>
                  </Box>
                </Box>
              </form>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default UsersEditModal;
