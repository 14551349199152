import React from "react";
import "./App.scss";
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./app/routes/routes";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import lightTheme from "./assets/styles/lightTheme";
import createCache from "@emotion/cache";
import { CacheProvider, jsx, css } from "@emotion/react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import darkTheme from "./assets/styles/darkTheme";
import { ToastContainer } from "react-toastify";
import { Alert } from "@mui/material";
const cache = createCache({
  key: "css",
  prepend: true,
});
// meter cache provider
function App() {
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <CacheProvider value={cache}>
          <ThemeProvider theme={darkTheme}>
            <CssBaseline />

            <div className="App ">
              <BrowserRouter>
                <AppRoutes />
              </BrowserRouter>
            </div>
          </ThemeProvider>
        </CacheProvider>
      </LocalizationProvider>
    </>
  );
}

export default App;
