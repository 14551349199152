import React, { useCallback, useState, useEffect, useMemo } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import { useFormik } from "formik";
import * as yup from "yup";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styles } from "../../assets/styles/styles";

import Dragndrop from "../components/dragndrop/Dragndrop";
import Preview from "./dragndrop/Preview";
import { MdHome } from "react-icons/md";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { uploadFlyer, uploadLiveEvent } from "../store/actions/items";

import uploadImage from "../utils/uploadImage";
const MySwal = withReactContent(Swal);

const homeItems = [
  {
    value: "home",
    label: "Flyer Carrusel",
    fields: [
      "category",
      "cdate",
      "description",
      "details",
      "imageURL",
      "name",
      "streamURL",
      "tags",
    ],
  },
  {
    value: "live",
    label: "Banner Evento en Vivo",
    fields: ["cdate", "imageURL", "name", "tags", "link"],
  },
];

const AddItems = ({ refresh }) => {
  const [value, setValue] = useState("home");
  const [files, setFiles] = useState([]);
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  useEffect(() => {
    // Clear added items when the component is loaded
    setFiles([]); // Clear the added files array
  }, []);

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks
    files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [files]);

  const formik = useFormik({
    initialValues: {
      contentType: "home",
      name: "",
      category: "",
      details: "",
      imageURL: "",
      streamURL: "",
      description: "",
      files: null,
    },
    enableReinitialize: true,
    validationSchema: () => {
      if (value === "home") {
        return yup.object({
          name: yup.string("Ingrese el nombre").required("Campo Obligatorio"),
          description: yup
            .string("Ingrese una descripción")
            .required("Campo Obligatorio"),
        });
      } else if (value === "live") {
        return yup.object({
          name: yup.string("Ingrese el nombre").required("Campo Obligatorio"),
        });
      }
      return yup.object({});
    },
    onSubmit: (values) => {
      SubmitForm(values);
    },
  });

  const SubmitForm = async (values) => {
    console.log(values);
    MySwal.fire({
      title: "¿Confirm?",
      text: "¿Está seguro que quiere agregar este item?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Agregar",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        MySwal.fire("Item Guardado!", "", "success");

        // Upload to Firebase Storage

        const file = files[0];
        const folder = value === "home" ? `items/home` : `items/liveEvent`;
        const imageURL = await uploadImage(file, folder);

        values.imageURL = imageURL;

        const homeData = {
          name: values.name,
          description: values.description,
          imageURL: values.imageURL,
          category: "events",
          details: values.details,
          imageURL: values.imageURL,
          streamURL: values.streamURL,
        };
        const liveData = {
          name: values.name,
          link: values.link,
          imageURL: values.imageURL,
        };
        try {
          if (value === "home") {
            await uploadFlyer(homeData, "home");
          } else if (value === "live") {
            await uploadLiveEvent(liveData, "liveEvent");
          }
          refresh();
          removeAll(); // Clear the files after submission
        } catch (error) {
          MySwal.fire("Hubo un error al guardar el item", "", "error");
        }
      }
    });
  };

  const onDrop = useCallback((acceptedFiles) => {
    setFiles(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
  }, []);

  const removeAll = () => {
    setFiles([]);
  };

  const selectedFields = useMemo(() => {
    const selectedItem = homeItems.find((item) => item.value === value);
    return selectedItem ? selectedItem.fields : [];
  }, [value]);

  return (
    <Grid container alignItems="center" justifyContent="center">
      <Grid
        item
        lg={6}
        sm={8}
        xs={12}
        sx={styles.cardStyle}
        className=" add-media-card mb-4 "
      >
        <form onSubmit={formik.handleSubmit} className="m-auto p-2 w-75">
          <Typography
            sx={styles.cardTitleStyle}
            variant="h6"
            className="center"
          >
            Agregar Items
          </Typography>
          <Box sx={styles.innerCardStyle}>
            <TextField
              fullWidth
              id="contentType"
              name="contentType"
              select
              label="Seleccione"
              value={value}
              onChange={handleChange}
              helperText="Escoja la sección que desea cambiar"
              SelectProps={{
                MenuProps: {
                  style: {
                    maxWidth: 0,
                    maxHeight: 300,
                    position: "absolute",
                    getContentAnchorEl: () => null,
                  },
                  disableScrollLock: true,
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MdHome />
                  </InputAdornment>
                ),
              }}
              variant="standard"
            >
              {homeItems.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>

            {selectedFields.includes("name") && (
              <TextField
                margin="dense"
                variant="standard"
                fullWidth
                id="name"
                name="name"
                label="Nombre"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            )}
            {selectedFields.includes("link") && (
              <TextField
                margin="dense"
                variant="standard"
                fullWidth
                id="link"
                name="link"
                label="Enlace"
                value={formik.values.link}
                onChange={formik.handleChange}
                error={formik.touched.link && Boolean(formik.errors.link)}
                helperText={formik.touched.link && formik.errors.link}
              />
            )}
            {selectedFields.includes("description") && (
              <TextField
                margin="dense"
                variant="standard"
                fullWidth
                id="description"
                name="description"
                label="Descripción"
                type="description"
                value={formik.values.description}
                onChange={formik.handleChange}
                error={
                  formik.touched.description &&
                  Boolean(formik.errors.description)
                }
                helperText={
                  formik.touched.description && formik.errors.description
                }
              />
            )}

            {selectedFields.includes("details") && (
              <TextField
                margin="dense"
                variant="standard"
                fullWidth
                id="details"
                name="details"
                label="Detalles"
                type="details"
                value={formik.values.details}
                onChange={formik.handleChange}
                error={formik.touched.details && Boolean(formik.errors.details)}
                helperText={formik.touched.details && formik.errors.details}
              />
            )}

            {selectedFields.includes("streamURL") && (
              <TextField
                margin="dense"
                variant="standard"
                fullWidth
                id="streamURL"
                name="streamURL"
                label="URL de transmisión"
                value={formik.values.streamURL}
                onChange={formik.handleChange}
                error={
                  formik.touched.streamURL && Boolean(formik.errors.streamURL)
                }
                helperText={formik.touched.streamURL && formik.errors.streamURL}
              />
            )}
            <Box item md={6} sx={styles.uploadBoxStyle}>
              {files.length === 0 ? (
                <div className=" center m-auto">
                  <Dragndrop onDrop={onDrop} accept={"image/*"} maxFiles={1} />
                </div>
              ) : (
                <Preview files={files} removeAll={removeAll} />
              )}
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                marginTop: "20px",
                marginBottom: "5px",
              }}
            >
              <Button
                className="w-50"
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
              >
                Crear
              </Button>
            </Box>
          </Box>
        </form>
      </Grid>
    </Grid>
  );
};

export default AddItems;
