import React, { useCallback, useState, useEffect, useMemo } from "react";
import IconButton from "@mui/material/IconButton";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { useDropzone } from "react-dropzone";
import { styles } from "../../../assets/styles/styles";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import lightTheme from '../../../assets/styles/lightTheme';

const Dragndrop = ({ onDrop, accept ,maxFiles}) => {

  const {
    getRootProps,
    getInputProps,
    open,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: {
      'image/*': ['.jpeg', '.png','.jpg']
    },
    onDrop,
    maxFiles,
  });
  const style = useMemo(
    () => ({
      ...styles.baseStyle,
      ...(isDragActive ? styles.activeStyle : {}),
      ...(isDragAccept ? styles.acceptStyle : {}),
      ...(isDragReject ? styles.rejectStyle : {}),
    }),
    [isDragActive, isDragReject]
  );


  return (
    <>
    
   
      <div className="drag-n-drop " {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <IconButton
          color="primary"
          aria-label="upload picture"
          component="span"
          sx={{ textIndent: 30 , color:lightTheme.palette.primary.main,boxShadow:'none'}}
          onClick={open}
        >
          <PhotoCamera />
        </IconButton>

        {isDragActive ? (
          <p>Arrastre la imagen ...</p>
        ) : (
          <p>Arrastre la imagen o haga click para subir un archivo</p>
        )}
      </div>
    </>
  );
};

export default Dragndrop;
