import { storage } from "../../firebase/firebase-config";

const uploadImageWithTimestamp = async (file, folder) => {
  const timestamp = Date.now();
  const fileNameWithDate = `${file?.name}_${timestamp}`;

  const storageRef = storage.ref();
  const imageRef = storageRef.child(`${folder}/${fileNameWithDate}`);

  await imageRef.put(file);
  const imageURL = await imageRef.getDownloadURL();

  return imageURL;
};

export default uploadImageWithTimestamp;
