import { useState } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Typography from "@mui/material/Typography";
import PersonIcon from "@mui/icons-material/Person";
import MenuList from "@mui/material/MenuList";
import LabelIcon from "@mui/icons-material/Label";
import { useDispatch, useSelector } from "react-redux";
import { MdPerson, MdPersonAdd } from "react-icons/md";

import { logoutUser, setIsLoadingAuth } from "../../store/actions/auth";
import { useLocation, useNavigate } from "react-router-dom";
import { IconButton, useTheme } from "@mui/material";

const menuStyle = {
  border: "none !important",
  minWidth: 300,
  borderRadius: 1,
  top: "10px",
  left: "-35px", // background:"white"
};

const ProfileDropdownMenu = () => {
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  let location = useLocation();
  const theme=useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const dispatch = useDispatch();
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const handleLogout = (event) => {
    dispatch(logoutUser());
    sessionStorage.clear();
    window.location.reload(false);
    dispatch(setIsLoadingAuth(true));
    if (location.pathname !== "/home") {
      navigate("/");
    } else {
      // window.location.reload(false);
    }
  };
  return (
    <>
   <IconButton
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{
          borderRadius: "18px !important",
          boxShadow: 1,
          backgroundColor:theme.palette.primary.main
        }}
        color="secondary"
        variant="contained"
      >
        <MdPerson />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        sx={menuStyle}
        open={open}
        onClose={handleClose}
        menulistprops={{
          "aria-labelledby": "basic-button",
        }}
        disableScrollLock={true}
      >
        <MenuList
          dense
          menulistprops={{
            sx: { width: anchorEl && anchorEl.offsetWidth }, // <-- The line that does all
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={handleClose} className="mb-2">
            <ListItemIcon className="text-dark">
              <PersonIcon fontSize="small" color="primary" />
            </ListItemIcon>
            <ListItemText sx={{ color: "white" }}>{user?.email}</ListItemText>
            <Typography></Typography>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <ListItemIcon >
              <LabelIcon fontSize="small" color="primary" />
            </ListItemIcon>
            <ListItemText sx={{ color: "white" }}>Administrador</ListItemText>
            <Typography></Typography>
          </MenuItem>
          <MenuItem>
            <Button
              color="error"
              variant="contained"
              onClick={() => handleLogout()}
              sx={{ margin: "10px auto" }}
            >
              Cerrar Sesión
            </Button>
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  );
};

export default ProfileDropdownMenu;
