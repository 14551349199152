import { createTheme } from "@mui/material/styles";
import { styles } from "./styles";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#06C8FD",
      secondary: "#F59800",
    },
    secondary: {
      main: "#181619",
      secondary: "#252525",
      terciary: "#2d2d30",
    },
    success: {
      main: "#69D482",
    },
    error: {
      main: "#FF5445",
    },
  },

  shape: {
    borderRadius: 8,
  },
  spacing: 10,
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },

  typography: {
    fontFamily: [
      '"Mulish"',
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
      "-apple-system",
      "BlinkMacSystemFont",
    ].join(","),

    body1: {
      fontSize: "0.9rem ",
      fontWeight: "700 ",
    },
  },

  transitions: {
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      standard: 300,
      complex: 375,
      enteringScreen: 225,
      leavingScreen: 195,
    },
    easing: {
      easeInOut: "cubic-bezier(0.4, 0, 0.2, 1)",
      easeOut: "cubic-bezier(0.0, 0, 0.2, 1)",
      easeIn: "cubic-bezier(0.4, 0, 1, 1)",
      sharp: "cubic-bezier(0.4, 0, 0.6, 1)",
    },
  },

  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: "#141414",
        },
        "body::-webkit-scrollbar": {
          width: "12px",
        },
        "body::-webkit-scrollbar-track": {
          backgroundColor: "#1c1c1c",
        },
        "body::-webkit-scrollbar-thumb": {
          backgroundColor: "#999999",
          borderRadius: "6px",
        },
        "body::-webkit-scrollbar-thumb:hover": {
          backgroundColor: "#666666",
        },
        html: {
          scrollBehavior: "smooth",
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: "0.85rem",
          color: "white",
          fontWeight: "800",

          boxShadow:
            "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          fontSize: "0.8rem",
          borderBottomWidth: 0,
          boxShadow:
            "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          fontSize: "0.8rem",
          color: "white",
          boxShadow:
            "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          background: "#282828",
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: "#282828",
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          color: "#dda74f",
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
          maxHeight: "64px",
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          marginBottom: 10,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: "0.6rem !important",
          marginLeft: "10px",
        },
      },
    },

    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiInputBase-input": {
            color: "white",
          },
          label: {
            color: "white",
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        input: {
          fontSize: "0.8rem !important",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          fontSize: "0.8rem !important",
        },
        root: {
          "&.Mui-focused": {
            fontWeight: "bold",
          },
        },
        notchedOutline: {
          borderWidth: 1,
          "&.Mui-focused": {
            fontWeight: "bold",
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: "white",
        },
        paper: {
          backgroundColor: "#181619",
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          minWidth: 300,
          textAlign: "center",
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          // Add specific style override for the list item inside the dropdown
          "&.dropdownListItem": {
            backgroundColor: "red", // Replace with your desired color
          },
          borderWidth: 0,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: "white",
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          color: "white",
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          border: "none",
          borderTopRightRadius: 1,
          borderTopLeftRadius: 1,
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          color: "white",
        },
        "&.Mui-active": {
          color: "white !important",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          backgroundColor: "#454545!important",
          color: "white",
          maxWidth: "200px",
          justifyContent: "center",

          span: { justifyContent: "center", color: "#2e3033" },
          button: {
            borderRadius: 0,
            boxShadow: "none",
            fontWeight: "800",
            fontFamily: '"Mulish", sans-serif',
          },
        },
        root: {
          borderBottom: "none",
          maxWidth: "200px",
          textAlign: "center",

          backgroundColor: "#8c8b8b !important",
        },
        footer: {
          backgroundColor: "#4b4b4b !important",

          borderBottomLeftRadius: 18,

          borderBottomRightRadius: 18,
        },
        body: {
          backgroundColor: "#fafafa",
        },
        paddingDefault: {
          padding: "40px 24px 40px 16px",
        },
      },
    },
    MUIDataTableToolbar: {
      styleOverrides: {
        root: {
          backgroundColor: "#4b4b4b !important",

          borderTopLeftRadius: 18,
          borderTopRightRadius: 18,
        },
      },
    },

    MuiMenu: {
      styleOverrides: {
        root: {
          maxWidth: "50px",
          // backgroundColor:"#1c1c1c",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          boxShadow: "none !important",
          color: "white",
          "&:hover": {
            backgroundColor: "#06C8FD",
          },
        },
      },
    },
  },
});

export default darkTheme;
