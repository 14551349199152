import { types } from "../types/types";

const initialState = {
  users: [],
  isLoading: false,
  requests: [],
};

export const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.getUsers:
      return {
        ...state,
        users: action.payload.users,
        // displayName: action.payload.displayName,
      };
    case types.getRequests:
      return {
        ...state,
        requests: action.payload.requests,
        // displayName: action.payload.displayName,
      };
    case types.addMoreUsers:
      return {
        ...state,
        users: [...state.users, ...action.payload],
        loading: false,
        error: null,
      };
    case types.addMoreRequests:
      return {
        ...state,
        requests: [...state.requests, ...action.payload],
        loading: false,
        error: null,
      };
    case types.isLoadingUsers:
      return {
        ...state,
        isLoading: action.payload.isLoading,
        // displayName: action.payload.displayName,
      };

    default:
      return state;
  }
};
