import { SET_IS_LOADING } from "../actions/items";
import { SET_NOTIFICATIONS } from "../actions/notifications";



const initialState = {
 notifications: [],
  isLoading: false,
};


export const notificationsReducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_NOTIFICATIONS:
        return {
          ...state,
          notifications: action.payload.notifications,
          // displayName: action.payload.displayName,
        };
  
      case SET_IS_LOADING:
        return {
          ...state,
          isLoading: action.payload.isLoading,
          // displayName: action.payload.displayName,
        };
  
      default:
        return state;
    }
  };
  