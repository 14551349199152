import React, {useState} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemButton from "@mui/material/ListItemButton";

function MenuButton({ item, open }) {
  const navigate = useNavigate();
  const navigateTo = (link) => {
    navigate(link);
  };
  const location = useLocation()




  
  return (
    <>
      <ListItemButton
        key={item.title}
        onClick={() => navigateTo(item.route)}
        className={item.route === `${location.pathname}` ? "isActive" : ""}
        sx={{
          minHeight: 48,
          justifyContent: open ? "initial" : "center",
          px: 2.5,
        }}
      >
        {item.icon && (
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 3 : "auto",
              justifyContent: "center",
            }}
          >
            {item.icon}
          </ListItemIcon>
        )}

        <ListItemText primary={item.title} sx={{ opacity: open ? 1 : 0 }} />
      </ListItemButton>
    </>
  );
}

export default MenuButton;
