import React, { useCallback, useState, useEffect, useMemo } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import { MdNotificationImportant } from "react-icons/md";
import { useFormik } from "formik";
import * as yup from "yup";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styles } from "../../assets/styles/styles";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import { daysOfTheWeek } from "./forms/daysOfTheWeek";
import ControlledSelect from "./forms/ControlledSelect";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);


const AddWeeklyNotifications = (props) => {
  const [disabled, setDisabled] = useState({});
  const [value, setValue] = useState("1"); // selected option
  const [selected, setSelected] = useState(false);
  const [selectedDay, setSelectedDay] = useState([daysOfTheWeek]);

  const handleSelectedDay = (e) => {
    setSelectedDay(
      daysOfTheWeek.map((o) =>
        o.id == e.target.value
          ? { ...o, isChecked: !o.isChecked }
          : { ...o, isChecked: false }
      )
    );
    const { name } = e.target;
    formik.setFieldValue(
      ["days.value"],
      [...formik.values["days.value"], name]
    );
  };
  function handleDisabled(id) {
    setDisabled((prevState) => ({ ...prevState, [id]: !prevState[id] }));
  }

  // const handleSelection = (e) => {
  //   const { checked, name } = e.target;
  //   if (checked) {
  //     setSelected(true);
  //     formik.setFieldValue("days", [...formik.values.days, name]);
  //   } else {
  //     setSelected(false);
  //     formik.setFieldValue(
  //       "days",
  //       formik.values.days.filter((v) => v !== name)
  //     );
  //   }
  // };

  const notificationNumbers = [
    {
      value: "1",
      label: "Notificación 1",
    },
    {
      value: "2",
      label: "Notificación 2",
    },
    {
      value: "3",
      label: "Notificación 3",
    },
    {
      value: "4",
      label: "Notificación 4",
    },
  ];

  const validationSchema = yup.object({
    title: yup.string("Ingrese el titulo").required("Campo Obligatorio"),
    description: yup.string("Ingrese una descripción").required("Campo Obligatorio"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      notificationNumber: 1,
      title: "",
      description: "",
      isActive: false,
      "days.value": "",
      "days.hour": "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      SubmitForm(values);
    },
  });

  const SubmitForm = (values) => {
    MySwal.fire({
      title: "¿Confirm?",
      text: "¿Esta seguro que quiere agregar este item?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Agregar",
      cancelButtonText: "Cancelar",
     
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        Swal.fire("Saved!", "", "success");
        console.log(values);
      } else if (result.isDenied) {
        console.log(values);
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };

  //   const handleSubmit = (event) => {
  //     console.log(
  // notificationNumber,
  // days,
  //  formik.values.title,
  // formik.values.description

  //     );
  //     event.preventDefault();
  //     event.stopPropagation();
  //   };

  return (
    <Box sx={styles.cardStyle} className=" add-media-card mb-4 ">
      <form
        onSubmit={formik.handleSubmit}
        className="m-auto p-2"
        style={{ width: "90%" }}
      >
        <Typography sx={styles.cardTitleStyle} variant="h6" className="center">
          Agregar Notificaciones Semanales
        </Typography>
        <Box sx={styles.innerCardStyle}>
          <TextField
            fullWidth
            label="Seleccione"
            id="notificationNumber"
            name="notificationNumber"
            select
            value={formik.values.notificationNumber}
            onChange={formik.handleChange}
            helperText="Escoja la sección que desea cambiar"
            SelectProps={{
              MenuProps: {
                style: {
                  maxWidth: 0,
                  maxHeight: 300,
                  position: "absolute",
                },
                disableScrollLock: true,
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MdNotificationImportant />
                </InputAdornment>
              ),
            }}
            variant="standard"
          >
            {notificationNumbers.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>

          <Box className="">
            <TextField
              margin="dense"
              variant="standard"
              fullWidth
              id="title"
              name="title"
              label="Nombre"
              type="text"
              value={formik.values.title}
              onChange={formik.handleChange}
              error={formik.touched.title && Boolean(formik.errors.title)}
              helperText={formik.touched.title && formik.errors.title}
            />
            <TextField
              margin="dense"
              variant="standard"
              fullWidth
              id="description"
              name="description"
              label="Descripción"
              type="text"
              value={formik.values.description}
              onChange={formik.handleChange}
              error={
                formik.touched.description && Boolean(formik.errors.description)
              }
              helperText={
                formik.touched.description && formik.errors.description
              }
            />
            {daysOfTheWeek.map((day) => (
              <Box
                key={day.id}
                sx={{ display: "flex", flexDirection: "row", mt: 2 }}
              >
                <Grid
                  item
                  xs={4}
                  sx={{ alignContent: "flex-start !important" }}
                >
                  <div>
                    <input
                      id={day.id}
                      type="checkbox"
                      name={day.day.value}
                      checked={daysOfTheWeek.isChecked}
                      onChange={handleSelectedDay}
                      onClick={() => handleDisabled(day.id)}
                    />
                    <label htmlFor={day.name}>{day.name}</label>
                  </div>
                </Grid>

                <Grid item xs={4} sx={{ marginLeft: "auto !important" }}>
                  <ControlledSelect
                    value={value}
                    options={day.day.hours}
                    onChange={formik.handleChange}
                    disabled={!disabled[day.id]}
                    name={formik.values["days.hour"]}
                  />
                </Grid>
              </Box>
            ))}
          </Box>

          <FormControl
            component="fieldset"
            sx={{
              marginLeft: "0px !important",
              flexDirection: "row",
              marginBottom: "15px",
            }}
          >
            <FormControlLabel
              sx={{ marginLeft: "0px !important", flexDirection: "row" }}
              value="isActive"
              label="Activos"
              labelPlacement="start"
              control={
                <Checkbox
                  name="isActive"
                  checked={formik.values.isActive}
                  onChange={formik.handleChange}
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              }
            />
          </FormControl>
          <FormHelperText sx={{ marginTop: "-24px !important" }}>
            Solo Usuarios Activos
          </FormHelperText>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              marginTop: "20px",
              marginBottom: "5px",
            }}
          >
            <Button
              className="w-50"
              color="primary"
              variant="contained"
              fullWidth
              type="submit"
              onClick={formik.handleSubmit}
            >
              Enviar
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default AddWeeklyNotifications;
