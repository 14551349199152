import React, { useEffect } from "react";
import TableFooter from "@mui/material/TableFooter";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import MuiTablePagination from "@mui/material/TablePagination";
import { withStyles } from "tss-react/mui";

const defaultFooterStyles = {};

const CustomFooter = ({
  count,
  classes,
  textLabels,
  rowsPerPage,
  page,
  changeRowsPerPage,
  changePage,
  totalCount,
}) => {
  const handleRowChange = (event) => {
    changeRowsPerPage(event.target.value);
  };

  const handlePageChange = (_, page) => {
    changePage(page);
  };

  const footerStyle = {
    display: "flex",
    justifyContent: "flex-end",
    padding: "0px 24px 0px 24px",
  };

  useEffect(() => {

  }, [totalCount])
  

  return (
    <TableFooter sx={{ width: "100%" }}>
      <TableRow>
        <TableCell colSpan={1000}>
          {/* <button>Custom Option</button> */}

          <MuiTablePagination
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            labelRowsPerPage={""}
            labelDisplayedRows={({ from, to, count }) =>
              totalCount && totalCount <= to
                ? `${from}-${totalCount} ${textLabels.displayRows} ${
                    totalCount ? totalCount : count
                  }`
                : totalCount === 0
                ? ` ${0}`
                : `${from}-${to} ${textLabels.displayRows} ${
                    totalCount ? totalCount : 0
                  }`
            }
            rowsPerPageOptions={[]}
            backIconButtonProps={{
              "aria-label": textLabels.previous,
            }}
            nextIconButtonProps={{
              "aria-label": textLabels.next,
              disabled: totalCount && totalCount <= Math.min(page * rowsPerPage + rowsPerPage, count) ? true : totalCount === 0,
            }}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowChange}
            >
 
          </MuiTablePagination>
        </TableCell>
      </TableRow>
    </TableFooter>
  );
};

export default withStyles(CustomFooter, defaultFooterStyles, {
  name: "CustomFooter",
});
